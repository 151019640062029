//import Button from '../../components/button';
import { Button } from '@keoworld/gbl-ui-kit';
import styled from 'styled-components';
import Modal from '../../components/modal';
import ButtonGroup from '../../containers/button-group';

/**
 * 
 * @param {Object} props
 * @param {boolean} props.openModal 
 * @param {Function} props.setOpenModal
 */
const InitialModal = ({openModal, setOpenModal }) => {
  return <Modal modalOpen={openModal}>
      <ModalBody>
        <p>
          It is essential that all requested data and required documents are provided.
        </p>
        <p>
          In case there is any doubt, please contact your executive by phone or e-mail.
        </p>
        <ButtonGroup>
          <div className='space' />
          <Button onClick={() => setOpenModal(false)}>
            Start
          </Button>
          <div className='space' />
        </ButtonGroup>
      </ModalBody>
    </Modal>
};

export default InitialModal;

const ModalBody = styled.div`
  padding: 1em 2em;

  p {
    text-align: center;
  }
`;