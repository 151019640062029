export const COMPANY_HEADERS = [
  { id: 'denomination', label: 'Denominación' },
  { id: 'rfc', label: 'RFC' },
  { id:'activity', label: 'Actividad' },
  { id: 'amount', label: 'Monto solicitado' },
  { id: 'status', label: 'Estado'}
];

export const CUSTOMER_HEADERS = {
  OWNER_HEADERS: [
    { name: 'name', label: 'Business Legal Name' },
    { name: 'email', label: '% of Ownership' },
    { name: 'address', label: 'Title' },
    { name: 'actions', label: 'Actions' },
  ]
}