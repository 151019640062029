const theme = {
  colors: {
    primary: '#FF0045',
    primaryLight: '#FF7380',
    secondary: '#2C2E3F',
    secondaryLight: '#E6E8ED',
    backgroundDark: '#2C2E3F',
    backgroundLight: 'white',
    backgroundLightAlt: '#F6F7F8',
    backgroundGrey: '#F8F8F8',
    error: '#FFAEB6',
    errorDark: '#CB2431',
    acepted: '#00D182',
    teal: '#00BBBB',
    tealLight: '#00BFBF',
    grey: '#DDE1EA',
    alternative: '#00575B',
  },
  inputTheme: {
    borderColor: '#979797',
  },
  helpSectionTheme: {
    primary: '#00326E',
  },
  textTheme: {
    primaryColor: '#1C1C1C',
    primaryColorLight: '#838CA8',
    secondaryColor: 'white',
    alternativeColor: '#00575B',
    placeholderPrimaryColor: '#838CA8',
    fontFamily: 'Source Sans Pro',
    fontFamilyAlternative: 'Ubuntu',
    fontSize: {
      button: '0.9em',
      small: '0.8em',
      normal: '1em',
      h3: '1.25em',
      h2: '1.5em',
      h1: '2em',
    },
  },
  universalBorderRadius: '5px',
  contentMaxWidth: '820px',
  titleMaxWidth: '358px',
  paragraphMaxWidth: '358px;',
};

export default theme;
