import React, { createContext, useState, useEffect } from "react"
import { signIn, authSignOut, authStateChangeSubscription } from "../helpers/firebase"

export const UserContext = createContext({ user: undefined })

const UserProvider = ({children}) => {
  const [user, setUser] = useState(undefined);


  useEffect (() => {
    const userActions = {
      SignIn: authSignIn,
      SignOut: signOut
    };
    setUser(userActions)
  }, [])

  useEffect(() => {
    const onAuthChange = async (user) => {
      setUser(prev => ({ ...prev, userAuth: user}) )
    }
    const unsubscribe = authStateChangeSubscription(onAuthChange)

    return () => {
      unsubscribe()
    }
  }, [])


	const authSignIn = (token) => 
    signIn(token)

  const signOut = () => {
    localStorage.clear()
    authSignOut()
  }
  
  return (
    <UserContext.Provider value={user}>{children}</UserContext.Provider>
  );
};

export default UserProvider;
