import { Button, Formatters, Icon } from '@keoworld/gbl-ui-kit'
import { useRef } from 'react'
import styled, { css } from 'styled-components'
import { kilobyteConverter } from '../utilities/functions/kilobytes-translator'

/**
 * @param {DocumentType} props
 * @returns
 */
const InputFile = (props) => {
  const {
    allowUpload = true,
    className,
    documentName,
    isDisabled,
    file,
    label,
    onChange,
    onClickDelete,
    onClickDownload,
    onClickComment,
    warning
  } = props

  const inputElement = useRef()

  const uploadFile = () => inputElement.current.click()

  const onHandleChange = (event) => {
    const files = event.target.files
    if (files && onChange) onChange(files)
  }

  return (
    <DocumentStyled
      className={className}
      isThereFile={Boolean(file)}
      warning={warning}
    >
      <div className='icon-group'>
        {typeof onClickDownload === 'function' && file?.id && (
          <button onClick={onClickDownload} disabled={isDisabled}>
            <Icon name='file_download' type='outlined' />
          </button>
        )}
        {typeof onClickComment === 'function' && (
          <button onClick={onClickComment}>
            <Icon name='add_comment' type='outlined' />
          </button>
        )}
        {typeof onClickDelete === 'function' && (
          <button onClick={onClickDelete} disabled={isDisabled}>
            <Icon name='delete' type='outlined' />
          </button>
        )}
      </div>
      <div>
        <div className='document-label'>{label.toLowerCase()}</div>
        {file && (
          <div className='document'>
            <div className='file-name'>
              <Icon name='attach_file' />
              <span>{file.name}</span>
            </div>
            {file?.size && (
              <div className='file-size'>
                {Formatters.numberFormat(
                  kilobyteConverter(file.size, 'MB'),
                  'es-MX',
                  {
                    maximumFractionDigits: 2
                  }
                )}{' '}
                MB
              </div>
            )}
          </div>
        )}
      </div>
      <input
        ref={inputElement}
        id={documentName}
        name={documentName}
        accept={['.pdf', 'image/*', '.doc', '.docx', '.xlsx', '.xls', '.csv']}
        filename={file?.name}
        onChange={onHandleChange}
        type='file'
        value=''
        hidden
      />
      {allowUpload /*!file &&*/ && (
        <Button disabled={isDisabled} onClick={uploadFile}>
          {file ? 'Update File' : 'Upload File'}
        </Button>
      )}
    </DocumentStyled>
  )
}

const DocumentStyled = styled.div`
  margin-top: 0.5rem;
  padding: 10px;
  min-height: 50px;
  width: 100%;
  //max-width: 25em;
  //min-width: 700px;
  //max-width: 700px;
  border: 1px solid
    ${({ theme }) => theme.colors.grayShades[300].backgroundColor};
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  .document-label {
    text-transform: capitalize;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 1rem;
    max-width: 44em;
  }
  button {
    height: 25px;
  }

  .icon-group {
    display: none;
  }

  ${({ isThereFile }) => {
    if (isThereFile) {
      return css`
        position: relative;
        height: 100px;

        padding: 2rem;

        .document {
          .file-name {
            display: flex;
            align-items: center;
            font-size: 10pt;
            .icon {
              font-size: 20px;
            }
          }
          .file-size {
            margin-left: 20px;
            opacity: 30%;
            font-size: 8pt;
          }
        }

        .icon-group {
          display: block;
          position: absolute;
          top: 5px;
          right: 1.5rem;

          button {
            cursor: pointer;
            border: none;
            background-color: transparent;

            height: 30px;
            width: 40px;
            .icon {
              font-size: 20px;
            }
          }
        }
      `
    }
  }}

  ${({ warning, theme }) => {
    if (warning)
      return css`
        border-color: ${theme.alertTheme.error.backgroundColor};
      `
  }}
`

export default InputFile

/**
 * @typedef DocumentType
 * @property {string} allowedDocumentTypes
 * @property {boolean} allowUpload
 * @property {string} className
 * @property {string} documentName
 * @property {boolean} isDisabled
 * @property {FileObject} file
 * @property {string} label
 * @property {Function} onChange
 * @property {Function} onClickDelete
 * @property {Function} onClickDownload
 * @property {Function} onClickComment
 * @property {boolean} warning
 */

/**
 * @typedef FileObject
 * @property {string} name
 * @property {number} size
 */
