import { useState } from 'react'
import Confetti from 'react-dom-confetti'
import { default as LocalButton } from '../../components/button'
import ButtonGroup from '../../containers/button-group'
import FinalModal from '../modal-bodies/final-modal'
import styled from 'styled-components'
import { Button } from '@keoworld/gbl-ui-kit'
import {
  getCustomerInstance,
  instanceTransition
} from '../../services/workflow'
import { CUSTOMER_WF_STATES } from '../../utilities/constants/models'
import { updateCustomer } from '../../services/customer'
import { useContext } from 'react'
import { CustomerContext } from '../../providers/customer-provider'
import { getCustomerInfo } from '../../services/customer'
import { finalValidation } from '../../utilities/functions/format-form'
import { getCustomerFiles } from '../../services/files'

/**
 *
 * @param {Object} props
 * @param {Function} props.onReturn
 * @param {Function} props.onNext
 */
const Terms = ({ onReturn }) => {
  const [openModal, setOpenModal] = useState(false)
  const [inputsErrors, setInputsErrors] = useState([])
  const [termsStatus, setTermsStatus] = useState({
    application: {
      accepted: false,
      openTerms: false
    },
    consultations: {
      accepted: false,
      openTerms: false
    }
  })
  const [isLoading, setIsLoading] = useState(false)
  const { customer } = useContext(CustomerContext)

  const setOpenTerms = (terms) => {
    setTermsStatus((prev) => ({
      ...prev,
      [terms]: {
        ...prev[terms],
        openTerms: !prev[terms]?.openTerms
      }
    }))
  }

  const handleCheckBox = (e, typeTerm) => {
    const type = e.target?.name
    const isChecked = e.target?.checked
    let state = {
      ...termsStatus,
      [type]: {
        ...termsStatus[type],
        accepted: isChecked
      }
    }
    setTermsStatus(state)
    if(state[typeTerm].accepted) {
      setTermsStatus((prev) => ({
        ...prev,
        [typeTerm]: {
          ...prev[typeTerm],
          openTerms: true
        }
      }))
    }
  }

  const validateForm = async (termsValid) => {
    let isFormComplete = false
    try {
      const customerData = await getCustomerInfo(customer.customerId)
      const filesData = await getCustomerFiles(customer.customerId)
      const { isFinish, errorFields } = await finalValidation(
        customerData.data,
        filesData.data
      )
      if (!termsValid) errorFields.push('<strong> Accept all terms </strong>')
      setInputsErrors(errorFields)
      isFormComplete = isFinish
    } catch (error) {
      console.error(error)
    }
    return termsValid && isFormComplete
  }

  const handleSubmit = async () => {
    setIsLoading(true)
    const { application, consultations } = termsStatus
    const termsValid = application?.accepted && consultations?.accepted
    const formValid = await validateForm(termsValid)
    if (formValid)
      try {
        await updateCustomer({
          id: customer?.customerId,
          agreement: {
            authApplication: application?.accepted,
            authCreditInformation: consultations?.accepted
          }
        })
        const {
          data: { body }
        } = await getCustomerInstance(customer?.customerId)
        const { instance } = body
        /* TODO : validate instance?.currentState ?? 'cust_externalOnBoarding' */
        const { transition } = CUSTOMER_WF_STATES.cust_externalOnBoarding
        const transitionBody = {
          workflowId: instance?.workflowId,
          principalId: instance?.instanceId,
          event: transition?.event,
          form: {
            'wfState:state': transition?.wfState,
            id: Number(customer?.customerId)
          }
        }
        await instanceTransition(transitionBody)
        setOpenModal(true)
      } catch (error) {
        console.log(`Error: ${error?.message ?? 'Unknown.'}`)
      }
    setIsLoading(false)
  }

  const setErrorMessages = (e) => {
    return {__html: e.toString().replace(/,/g, ', ') };
  }

  return (
    <TermsContainer>
      <Confetti
        className='confetti'
        active={openModal}
        config={{
          spread: '360',
          stagger: '5',
          elementCount: '200',
          startVelocity: '40',
          colors: ['#FF4759', '#FF7380']
        }}
      />
      <StyledCheckbox>
        <input
          type='checkbox'
          id='application-check'
          name='application'
          onChange={(e) => handleCheckBox(e, 'application')}
        />
        <label htmlFor='application-check'>
          <p>
            I agree with the terms of the <b>AUTHORIZATION OF APPLICATION.</b>
          </p>
        </label>
        <p className='toggle-terms' onClick={() => setOpenTerms('application')}>
          Click here to {termsStatus?.application?.openTerms ? 'close' : 'open'}{' '}
          the terms
        </p>
      </StyledCheckbox>
      {termsStatus?.application?.openTerms && (
        <p>
          Under protest to tell the truth that the information herein is true
          and that the resources with which I will pay the Credit have been or
          will be obtained or generated through a source of legitimate origin
          and that the destination that I will give to the resources obtained
          under this Contract will be intended only for purposes permitted by
          law. I authorize "KEO OPERATIONS LLC, to review my identification
          file, as well as other documentation and financial, commercial,
          operational, history or credit information and of any other nature
          that is provided by me or by third parties with my authorization, or
          to any of the entities associated with KEO OPERATIONS LLC”. By signing
          this Request, I express my knowledge and agreement with the provisions
          of the declarations and clauses of the contract integrated into this
          document.
        </p>
      )}
      <StyledCheckbox>
        <input
          type='checkbox'
          id='consultations-check'
          name='consultations'
          onChange={(e) => handleCheckBox(e, 'consultations')}
        />
        <label htmlFor='consultations-check'>
          <p>
            I agree with the terms of the{' '}
            <b>CREDIT REPORT AUTHORIZATION AND RELEASE.</b>
          </p>
        </label>
        <p
          className='toggle-terms'
          onClick={() => setOpenTerms('consultations')}
        >
          Click here to{' '}
          {termsStatus?.consultations?.openTerms ? 'close' : 'open'} the terms
        </p>
      </StyledCheckbox>
      {termsStatus?.consultations?.openTerms && (
        <p>
          I hereby authorize "KEO OPERATIONS LLC” to request, obtain or verify
          directly or through any Credit Information Society in the present or
          in the future and as many times as it deems necessary and appropriate,
          all credit information of the subscriber. I note that I know the
          nature and scope of the information that will be requested, the use
          that will be made of such information and the fact that periodic
          consultations of my credit history may be made.
        </p>
      )}
      <ButtonGroup>
        <LocalButton onClick={() => onReturn()}>Back</LocalButton>
        <Button solid disabled={isLoading} onClick={() => handleSubmit()}>
          Send
        </Button>
      </ButtonGroup>
      {inputsErrors.length > 0 ? (
        <div className='formErrors'>
          {inputsErrors.map((e) => {
            return <div>
              <p dangerouslySetInnerHTML={setErrorMessages(e)}></p>
            </div>
          })}
        </div>
      ) : (
        <></>
      )}
      <FinalModal openModal={openModal} onSubmit={() => {}} />
    </TermsContainer>
  )
}

export default Terms

const StyledCheckbox = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;

  .toggle-terms {
    text-decoration: underline;
    padding: 0 0.5em;
    cursor: pointer;
  }
`
const TermsContainer = styled.div`
  .confetti {
    margin: 0 auto;
    position: absolute !important;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .formErrors {
    width: 60%;
    margin: auto;
    text-align: center;
  }
`
