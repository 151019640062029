import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'
import { InputFile } from '../components/input'
import { Input, Select } from '@keoworld/gbl-ui-kit'
//import Select from '../components/select';
import TextArea from '../components/text-area'
import { validateForm } from '../utilities/functions/validators'

export const INPUT_TYPE = {
  INPUT: 'input',
  TEXT_AREA: 'text-area',
  SELECT: 'select',
  FILE: 'file'
}

export const VALIDATOR_TYPES = {
  STRING: 'string',
  NUMBER: 'numbers',
  POSITIVE_NUMBER: 'positivesNumbers',
  EMAIL: 'email',
  ALPHANUMERIC: 'alphaNum',
  ANY: 'any',
  ALPHANUMERIC_SPACE: 'alphaNumSpace',
  ALPHANUMERIC_COMMA: 'alphaNumWithComma',
  STRING_SPACE: 'stringSpace',
  PHONE: 'phone',
  ZIP_CODE: 'zipCode',
  PERCENTAGE: 'percentage',
  LADA: 'lada',
  RFC: 'rfc',
  FUTURE_DATES: 'futureDates',
  NUMBER_INTERIOR: 'numberInterior',
  NUMBER_EXTERIOR: 'numberExterior',
  SCORE: 'score',
  TERM: 'term',
  DECIMAL: 'decimal',
  IS_MANDATORY: 'mandatory'
}

/**
 * Return <Form /> Component
 * @param {Object[]} skema Object array with form metadata description
 * @param {Object} controller Object form
 * @param {Function} setController Function to update form
 */
export default function Form(params) {
  const {
    className,
    skema,
    controller,
    setController,
    isWide,
    formErrors,
    setFormErrors,
    isValidating = true
  } = params
  const handleInputChange = (event) => {
    const name = event.target.name
    const value =
      event.target.type === 'file' && event.target.files.length > 0
        ? event.target.files[0]
        : event.target.value

    setController((form) => ({
      ...form,
      [name]: value
    }))
  }

  useEffect(() => {
    const validateErrors = () => {
      const errors = validateForm(skema, controller)
      setFormErrors && setFormErrors(errors)
    }
    validateErrors()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidating, controller])

  return (
    <FormStyled className={className} isWide={isWide}>
      {skema.map(
        (
          {
            label,
            name,
            placeholder,
            title,
            type = 'text',
            errorMessage,
            isRequired = false,
            component = INPUT_TYPE.INPUT,
            options = [],
            rows = 1,
            condition
          },
          index
        ) => {
          if (condition && !condition(controller))
            return <React.Fragment key={`empty-${index}`} />
          const error = formErrors && isValidating && formErrors[name]
          return (
            <div className='input' key={`input${index}`}>
              {component !== INPUT_TYPE.FILE && (
                <div className='input-label' title={title}>
                  {label}
                </div>
              )}
              {component === INPUT_TYPE.INPUT && (
                <Input
                  className='input-component'
                  type={type}
                  name={name}
                  title={title}
                  onChange={handleInputChange}
                  placeholder={placeholder}
                  required={isRequired}
                  value={controller[name] ?? ''}
                  error={error ? errorMessage || 'Required Field' : false}
                />
              )}
              {component === INPUT_TYPE.SELECT && (
                <Select
                  className='input-component'
                  onChange={handleInputChange}
                  name={name}
                  value={controller[name] ?? ''}
                  error={error ? errorMessage || 'Required Field' : false}
                >
                  <option value={''} disabled>
                    Select an option
                  </option>
                  {options.map((option, index) => (
                    <option value={option?.value} key={`option-${index}`}>
                      {option.label}
                    </option>
                  ))}
                </Select>
              )}
              {component === INPUT_TYPE.TEXT_AREA && (
                <TextArea
                  name={name}
                  className='text-area'
                  rows={`${rows}`}
                  onChange={handleInputChange}
                  placeholder={placeholder}
                  error={error ? errorMessage || 'Required Field' : false}
                />
              )}
              {component === INPUT_TYPE.FILE && (
                <InputFile
                  fileLabel={label}
                  label={controller[name]?.name}
                  name={name}
                  handleInputFile={(e) => handleInputChange(e)}
                />
              )}
            </div>
          )
        }
      )}
      {skema.length % 2 !== 0 && <div className='input' />}
    </FormStyled>
  )
}

const FormStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  width: 100%;

  font-size: ${({ theme }) => theme.textTheme.fontSize.p};

  .input {
    padding: 0.3em;
    width: 19em;

    .input-label {
      text-align: left;
    }

    .input-component {
      min-width: 15em;
    }

    ${(props) =>
      props.isWide &&
      css`
        display: flex;
        .input-label {
          width: 50%;
          text-align: right;
          padding: 0 1em;
        }
      `}
  }
  .text-area {
    resize: none;
  }
`
