import React, { forwardRef } from 'react';
import styled from 'styled-components';

/**
 * 
 * @param {Object} props
 * @param {boolean} props.modalOpen - 
 * @param {Component} props.children -
 * @param {*} ref 
 */
function Modal({ modalOpen, children, ...rest }, ref) {
  return (
    <React.Fragment>
      {modalOpen && (
        <Background>
          <Content {...rest} ref={ref}>
            {children ?? <React.Fragment />}
          </Content>
        </Background>
      )}
    </React.Fragment>
  );
}

export default forwardRef(Modal);

const Background = styled.div`
  background-color: rgb(0, 0, 0, 0.8);
  position: fixed;
  z-index: 6;
  width: 100vw;
  height: 100vh;
  right: 0;
  top: 0;
`;

const Content = styled.div`
  position: absolute;
  background: #ffffff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  text-align: left;
  z-index: 7;
  margin-top: 8px;

  width: 90%;
  max-width: 900px;
  border-radius: 5px;
`;
