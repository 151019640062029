import { useContext, useEffect, useState } from 'react'
import BigCard from '../components/big-card'
import Stepper from '../containers/stepper'
import Layout from './layout'
import Information from './customer-steps/information'
import Documents from './customer-steps/documents'
import Review from './customer-steps/review'
import Terms from './customer-steps/terms'
import InitialModal from './modal-bodies/initial-modal'
import { CustomerContext } from '../providers/customer-provider'
import { Icon, LoadingAnimation } from '@keoworld/gbl-ui-kit'
import { getCustomerInfo } from '../services/customer'

const STEPS_INFO = [
  { id: 'info', label: 'FORM' },
  { id: 'docs', label: 'DOCS' },
  { id: 'revision', label: 'REVIEW' },
  { id: 'terms', label: 'TERMS' }
]

const Customer = () => {
  const [currStep, setCurrStep] = useState(0)
  const [fullForm, setFullForm] = useState({
    info: null,
    docs: null,
    revision: null,
    terms: null
  })
  const [openModal, setOpenModal] = useState(true)
  const [customerWfState, setCustomerWfState] = useState()
  const { customer } = useContext(CustomerContext)

  const handleNextStep = (stepForm, step) => {
    if (stepForm && step) {
      const currFullForm = { ...fullForm }
      currFullForm[step] = stepForm
      setFullForm(currFullForm)
    }
    setCurrStep((oldCurrStep) => oldCurrStep + 1)
  }

  const handleReturnStep = () => setCurrStep((oldCurrStep) => oldCurrStep - 1)

  const getStep = (idx) => {
    switch (idx) {
      case 0:
        return (
          <Information
            step={STEPS_INFO[idx].id}
            formState={fullForm[STEPS_INFO[idx].id]}
            onNext={handleNextStep}
          />
        )
      case 1:
        return (
          <Documents
            step={STEPS_INFO[idx].id}
            formState={fullForm[STEPS_INFO[idx].id]}
            onReturn={handleReturnStep}
            onNext={handleNextStep}
          />
        )
      case 2:
        return (
          <Review
            step={STEPS_INFO[idx].id}
            fullForm={fullForm}
            onReturn={handleReturnStep}
            onNext={/*()=>{}*/ handleNextStep}
          />
        )
      case 3:
        return <Terms step={STEPS_INFO[idx].id} onReturn={handleReturnStep} />
      default:
        return <div>Error</div>
    }
  }

  useEffect(() => {
    const getCustomer = async () => {
      const { data } = await getCustomerInfo(customer?.customerId)
      setCustomerWfState(data?.wfState)
    }
    if (customer?.customerId) getCustomer()
  }, [customer?.customerId])

  return (
    <Layout>
      {!customerWfState ? (
        <LoadingAnimation className='loading-customer' />
      ) : customerWfState === 'cust_externalOnBoarding' ? (
        <>
          <div className='main-container'>
            <div>
              <Stepper
                current={currStep}
                setCurrStep={setCurrStep}
                steps_info={STEPS_INFO}
              />
            </div>
            <BigCard>{getStep(currStep)}</BigCard>
          </div>
          <InitialModal openModal={openModal} setOpenModal={setOpenModal} />
        </>
      ) : (
        <div className='main-container'>
          <div className='card-container'>
            <div className='center'>
              <Icon name='check_circle' type='outlined' />
            </div>
            <h3>Thank you for completing the form </h3>
          </div>
        </div>
      )}
    </Layout>
  )
}

export default Customer
