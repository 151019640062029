import React, { createContext, useState, useEffect } from "react";
import { asyncDelayer } from "../utilities/dev";

export const ContentContext = createContext({ content: undefined });

const ContentProvider = ({children}) => {
  const [content, setContent] = useState(undefined);

  useEffect (() => {
    const actions = {
      UpdateContent: updateContent,
      Clear: clear
    };
    setContent(actions)
  }, [])

	const updateContent = (newContent) => {
    setContent((prevState) => ({ ...prevState, content: newContent}))
  }

  const clear = () => {
    asyncDelayer({ },100).then( resp => 
      setContent((prevState) => ({ ...prevState, content: null}))
    )
  }
  
  return (
    <ContentContext.Provider value={content}>{children}</ContentContext.Provider>
  );
};

export default ContentProvider;
