import { initializeApp } from 'firebase/app'
import { getAuth, signInWithCustomToken, signOut, onAuthStateChanged } from 'firebase/auth'

const app = initializeApp({
  apiKey: process.env.REACT_APP_API_KEY,
  appId: process.env.REACT_APP_ID,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET
})

export const auth = getAuth(app)

/**
 * @param {string} token
 * @returns
 */
export const signIn = async (token) => await signInWithCustomToken(auth, token)

/**
 * 
 * @returns 
 */
export const authSignOut = async () => await signOut(auth)

/**
 * 
 * @param {function} callback 
 * @returns 
 */
export const authStateChangeSubscription = (callback) => 
  onAuthStateChanged(auth, callback)