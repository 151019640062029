import React, { createContext, useEffect, useState } from "react";

export const CustomerContext = createContext()

export const CustomerProvider = ({children}) => {
  const [customer, setCustomer] = useState({})

  useEffect(() => {
    if (customer?.customerId) {
      localStorage.setItem('cust_id', customer.customerId)
    }
    if (localStorage?.cust_id && !customer?.customerId) {
      setCustomer(prev => ({ ...prev, customerId: localStorage.cust_id }) )
    }
  }, [customer])
  
  return (
    <CustomerContext.Provider value={{customer, setCustomer}}>
        {children}
    </CustomerContext.Provider>
  );
};
