import React, { useContext } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import Header from './containers/header';
import { UserContext } from './providers/user-provider';
import ROUTES from './utilities/routes';
import Customer from './views/customer';
import Login from './views/login';


const LayoutContainer = styled.div`
  display: flex;
  height: calc(100% - 70px);
  width: 100%;
`

/**
 * Return <App /> component 
 * */
const App = (props) => {
  const user = useContext(UserContext);

  //console.log('user', user, user?.userAuth )
  if ( (user === undefined || user?.userAuth === null) &&
    props.location.pathname !== ROUTES.LOGIN 
  ) return <Redirect to={ROUTES.LOGIN} />

  if (user?.userAuth && props.location.pathname === ROUTES.LOGIN)
    return <Redirect to={ROUTES.CUSTOMER} />
  

  return (
    <React.Fragment>
      {/* Header Switch*/}
      <Switch>
        <Route exact path={ROUTES.LOGIN} render={() => null} />
        <Route exact path={ROUTES.CUSTOMER} render={(props) => <Header isCustomer {...props} />} />
      </Switch>
      <LayoutContainer>
        {/* SideBar Switch*/}
        <Switch>
          <Route exact path={ROUTES.LOGIN} render={() => null} />
          <Route exact path={ROUTES.CUSTOMER} render={() => null} />
        </Switch>
        {/* Content Switch*/}
        <Switch>
          <Route exact path={ROUTES.LOGIN} component={Login} />
          <Route exact path={ROUTES.CUSTOMER} component={Customer} />
          {<Redirect to={ROUTES.LOGIN} />}
        </Switch>
      </LayoutContainer>
    </React.Fragment>
  )
}

const AppWithRouter = withRouter(App);

export default AppWithRouter;

