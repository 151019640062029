import axios from 'axios'
import { auth } from '../helpers/firebase'
import { API_HOST } from '../utilities/constants'

export const ENDPOINTS = {
  workflow: {
    versions: '/workflow/workflows/versions',
    instances: '/workflow/instances/',
    transition: '/workflow/instances/transition'
  },
  customer: {
    info: '/customer.info',
    update: '/customer.update'
  },
  file: {
    find: '/file.find',
    upload: '/file.upload',
    download: '/file.download'
  },
  user: {
    sendLink: '/onboarding.send'
  }
}

export const setUpAxios = () =>
  axios.interceptors.request.use(
    async (config) => {
      const currentUser = auth?.currentUser
      /* TEMP: Highly insecure */
      const publicEndpoint = ENDPOINTS.user.sendLink

      if (currentUser) {
        config.headers.authorization = `Bearer ${await currentUser.getIdToken()}`
      } else if(config.url !== publicEndpoint) {
        config.cancelToken = new axios.CancelToken((cancel) =>
          cancel('No user signed in')
        )
      }
      // Do something before request is sent
      if(config.url !== publicEndpoint)
        config.baseURL = `${API_HOST}/api`
      else 
        config.baseURL = `${process.env.REACT_APP_TEMP_PUBLIC_HOST}/api`
      return config
    },
    (error) => {
      console.log('error', error)
      // Do something with request error
      return Promise.reject(error)
    }
  )
