import { CUSTOMER_FORMS } from '../constants/form_skemas'

const formatInitialForm = (customer) => {
  const formInformation = {
    customerId: customer.id,
    locationId: customer.location?.id,
    name: customer.name,
    dba: customer.dba,
    startedAt: customer.startedAt,
    phone: customer.phone,
    'address:location': customer.location?.address,
    fax: customer.fax,
    'detail:location': customer.location?.detail,
    'state:location': customer.location?.state,
    'zipCode:location': customer.location?.zip,
    businessType: customer.businessType,
    'city:location': customer.location?.city,
    incorporationState: customer.incorporationState,
    'addressType:location': customer.location?.addressType,
    employees: customer.employees,
    website: customer.website
  }

  let clients = ''
  customer.clients?.forEach((client) => {
    if (!clients) clients = `${clients}${client.name}`
    else clients = `${clients},${client.name}`
  })
  const statementLegalIssues = String(customer.statement?.legalIssues)
  const statementLegalIssuesDischarged = String(
    customer.statement?.legalIssuesDischarged
  )
  const formFinancialInformation = {
    customerId: customer.id,
    statementId: customer.statement?.id,
    clients: clients,
    'cashSalesPercentage:statement': customer.statement?.cashSalesPercentage,
    'mainSupplier:statement': customer.statement?.mainSupplier,
    'internationalSalesPercentage:statement':
      customer.statement?.internationalSalesPercentage,
    'monthlySales:statement': customer.statement?.monthlySales,
    'creditSalesPercentage:statement':
      customer.statement?.creditSalesPercentage,
    'yearlySales:statement': customer.statement?.yearlySales,
    'nationalSalesPercentage:statement':
      customer.statement?.nationalSalesPercentage,
    'projectionNextYearlySales:statement':
      customer.statement?.projectionNextYearlySales,
    'inventory:statement': customer.statement?.inventory,
    'averageTicketItem:statement': customer.statement?.averageTicketItem,
    'legalIssues:statement':
      statementLegalIssues !== 'undefined' && statementLegalIssues !== 'null'
        ? statementLegalIssues
        : undefined,
    'legalIssuesDischarged:statement':
      statementLegalIssuesDischarged !== 'undefined' &&
      statementLegalIssuesDischarged !== 'null'
        ? statementLegalIssuesDischarged
        : undefined,
    'legalIssuesDischargedAt:statement':
      customer.statement?.legalIssuesDischargedAt
  }

  const formLoanRequest = {
    customerId: customer.id,
    loanRequestId: customer.loanRequest?.id,
    'amount:loanRequest': customer.loanRequest?.amount,
    'fundsUse:loanRequest': customer.loanRequest?.fundsUse
  }

  return { formInformation, formFinancialInformation, formLoanRequest }
}

const formatInitialOwners = (customerOwners = []) => {
  const formatedOwners = customerOwners.map((owner) => {
    const newOwner = { ...owner, ...owner.location }
    delete newOwner.location
    return newOwner
  })
  return formatedOwners
}

const updateForm = (whatForm, form, oldClients = []) => {
  let updatedform = {}
  if (whatForm === 'infoForm') {
    updatedform = {
      id: form.customerId,
      name: form.name,
      dba: form.dba,
      startedAt: form.startedAt,
      phone: form.phone,
      location: {
        id: form.locationId,
        address: form['address:location'],
        detail: form['detail:location'],
        city: form['city:location'],
        state: form['state:location'],
        zip: form['zipCode:location'],
        addressType: form['addressType:location']
      },
      fax: form.fax,
      businessType: form.businessType,
      incorporationState: form.incorporationState,
      employees: form.employees,
      website: form.website
    }
  } else if (whatForm === 'financialForm') {
    let array = form.clients.split(',') ?? []
    let clientsSend = []
    array.forEach((name) => {
      const names = oldClients.map((itm) => itm.name)
      const idx = names.indexOf(name)
      if (idx !== -1) clientsSend.push(oldClients[idx])
      else clientsSend.push({ name })
    })
    updatedform = {
      id: form.customerId,
      clients: clientsSend,
      statement: {
        id: form.statementId,
        cashSalesPercentage: Number(form['cashSalesPercentage:statement']),
        mainSupplier: form['mainSupplier:statement'],
        internationalSalesPercentage: Number(
          form['internationalSalesPercentage:statement']
        ),
        monthlySales: Number(form['monthlySales:statement']),
        creditSalesPercentage: Number(form['creditSalesPercentage:statement']),
        yearlySales: Number(form['yearlySales:statement']),
        nationalSalesPercentage: Number(
          form['nationalSalesPercentage:statement']
        ),
        projectionNextYearlySales: Number(
          form['projectionNextYearlySales:statement']
        ),
        inventory: Number(form['inventory:statement']),
        averageTicketItem: Number(form['averageTicketItem:statement']),
        legalIssues: form['legalIssues:statement'],
        legalIssuesDischarged: form['legalIssuesDischarged:statement'],
        legalIssuesDischargedAt:
          form['legalIssuesDischargedAt:statement'] ?? undefined
      }
    }
  } else {
    updatedform = {
      id: form.customerId,
      loanRequest: {
        id: form.loanRequestId,
        amount: form['amount:loanRequest'],
        fundsUse: form['fundsUse:loanRequest']
      }
    }
  }
  return updatedform
}

const formatCreateOwner = async (form) => {
  const formatForm = {
    id: form.id,
    birthdate: form['birthdate:owner'],
    email: form['email:owner'],
    location: {
      id: form.locationId,
      address: form['address:owner.location'],
      detail: form['detail:owner.location'],
      city: form['city:owner.location'],
      state: form['state:owner.location'],
      zip: form['zip:owner.location']
    },
    mobile: form['mobile:owner'],
    name: form['name:owner'],
    ownershipPercentage: form['ownershipPercentage:owner'],
    ssn: form['ssn:owner'],
    title: form['title:owner']
  }
  return formatForm
}

const formatInitialFormOwner = async (form) => {
  const formatForm = {
    id: form.id,
    locationId: form.location.id,
    'birthdate:owner': form.birthdate,
    'email:owner': form.email,
    'address:owner.location': form.location.address,
    'detail:owner.location': form.location.detail,
    'city:owner.location': form.location.city,
    'state:owner.location': form.location.state,
    'zip:owner.location': form.location.zip,
    'mobile:owner': form.mobile,
    'name:owner': form.name,
    'ownershipPercentage:owner': form.ownershipPercentage,
    'ssn:owner': form.ssn,
    'title:owner': form.title
  }
  return formatForm
}

const updateOwners = async (customerId, form) => {
  const formatForm = {
    id: customerId,
    owners: form
  }
  return formatForm
}

const finalValidation = async (data, files) => {
  let formValid = { isFinish: false, errorFields: [] }
  const mandatoryFields = [
    'name',
    'dba',
    'email',
    'phone',
    'employees',
    'website',
    'businessType',
    'startedAt',
    'wfStage',
    'wfState',
    'incorporationState',
    'statement',
    'location',
    'loanRequest'
  ]
  let fieldsNotValid = mandatoryFields.filter((itm) => {
    return (
      data[itm] === '' || data[itm] === null || !Object.keys(data).includes(itm)
    )
  })

  if (fieldsNotValid.length > 0)
    fieldsNotValid.unshift(
      '<strong>You must fill out the entire form:</strong>'
    )

  const mandatoryDocuments = []
  CUSTOMER_FORMS.FORM_DOCUMENTS.forEach((element) => {
    if (element.isRequired)
      mandatoryDocuments.push({
        fileTypeId: element.fileTypeId,
        label: element.label
      })
  })
  const filesNames = files.map((element) => element.typeId)
  const missingFiles = []
  mandatoryDocuments.forEach((element) => {
    if (!filesNames.includes(element.fileTypeId)) {
      missingFiles.length > 0
        ? missingFiles.push(element.label)
        : missingFiles.push(`<strong>Documents:</strong> ${element.label}`)
    }
  })

  fieldsNotValid = fieldsNotValid.map((e) => {
    if (e === 'location') return 'Fill all Customer Profile fields'
    if (e === 'statement') return 'Fill all Financial Information fields'
    if (e === 'loanRequest') return 'Fill all Loan Request fields'
    return e
  })

  const clients = data.clients?.length > 0
  const owners = data.owners?.length > 0
  if (!clients) fieldsNotValid.push('You must enter at least one Client')
  if (!owners) fieldsNotValid.push('You must enter at least one Owner')
  if (fieldsNotValid.length > 0) formValid.errorFields.push(fieldsNotValid)
  if (missingFiles.length > 0) formValid.errorFields.push(missingFiles)
  if (
    fieldsNotValid.length === 0 &&
    missingFiles.length === 0 &&
    clients &&
    owners
  )
    formValid.isFinish = true

  return formValid
}

export {
  formatInitialForm,
  formatInitialOwners,
  updateForm,
  formatCreateOwner,
  updateOwners,
  finalValidation,
  formatInitialFormOwner
}
