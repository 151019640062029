import React, { useContext, useEffect, useState } from 'react'
//import { Link } from 'react-router-dom';
import styled from 'styled-components'
import IMAGES from '../assets/images'
import { Button, Input } from '@keoworld/gbl-ui-kit'
//import Input from '../components/input';
import { CenteredLoader } from '../components/loader'
import { UserContext } from '../providers/user-provider'
//import { SignIn } from "../services/auth"
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
//import { authSignOut } from "../helpers/firebase"
import { CustomerContext } from '../providers/customer-provider'
import { requestLoginLink } from '../services/customer'

const Login = () => {
  const [form, setForm] = useState({})
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const { customer, setCustomer } = useContext(CustomerContext)
  const user = useContext(UserContext)
  const { search } = useLocation()

  const handleSubmit = async () => {
    console.log('sendingTo', customer?.customerId)
    try {
      await requestLoginLink(customer?.customerId)
    } catch (err) {
      console.log(`Error: ${err?.message ?? 'Unknown.'}`)
    }
  }

  useEffect(() => {
    const { token, customerId } = queryString.parse(search)
    //console.log("token, customerId", token, customerId)
    setCustomer({ customerId })
    const tokenLogin = async () => {
      setLoading(true)
      try {
        await user.SignIn(token)
        //throw new Error('error')
      } catch (err) {
        console.log(`Error: ${err?.message ?? 'Unknown in login'}`)
        //authSignOut()
        setError(true)
      }
      setLoading(false)
    }
    if (!user?.authUser) tokenLogin()
  }, [search, setCustomer, user])

  return (
    <SectionContainer>
      <img src={IMAGES.KEO_ISOLOGO} alt='Keo World' />
      <LoginContainer>
        {error && (
          <div className='email-input'>
            <p>Enter your email to receive a new sign-in link</p>
            <Input
              className='input-component'
              name={'email'}
              onChange={(e) => setForm({ email: e.target?.value })}
              placeholder={'user@mail.com'}
              required={true}
              value={form?.email}
            />
            <Button onClick={() => handleSubmit()}>Send me a link</Button>
          </div>
        )}
        {loading && <CenteredLoader height='100%' />}
      </LoginContainer>
    </SectionContainer>
  )
}

export default Login

const SectionContainer = styled.div`
  align-items: center;
  background-color: ${(props) => props.theme.colors.backgroundLight};
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  width: 100vw;

  img {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`
const LoginContainer = styled.div`
  align-items: center;
  background-color: ${(props) => props.theme.colors.backgroundDark};
  color: ${(props) => props.theme.textTheme.secondaryColor};
  display: flex;
  flex-grow: 1;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  border-radius: 45px 0 0 0;

  form {
    align-items: center;
    display: flex;
    flex-direction: column;

    button {
      width: 300px;
    }
  }

  a {
    color: ${(props) => props.theme.textTheme.secondaryColor};
    text-decoration: none;
  }

  .email-input {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${({ theme }) => theme.textTheme.primaryColor};

    & > * {
      margin: 1em 0;
    }
  }
`
