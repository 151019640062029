import styled, { css } from 'styled-components';
import { STEPS, ARROWS, ICONS } from '../assets/images';

const StyledButton = styled.button`
  background-color: ${(props) => props.solid ? props.theme.colors.primary : 'white'};
  border: ${(props) => props.withBorder ? `3px ${props.theme.colors.backgroundLightAlt} solid` : '1px transparent solid' };
  border-radius: ${(props) => props.theme.universalBorderRadius};
  color: ${(props) => props.solid ? 'white' : props.theme.textTheme.alternativeColor};
  cursor: pointer;
  //font-family: 'Ubuntu';
  font-size: ${(props) => props.theme.textTheme.fontSize.button};
  font-weight: 600;
  margin: ${(props) => props.margin ? props.margin : '0'};
  padding: ${(props) => props.padding ? props.padding : '8px 20px'};

  display: flex;
  align-items: center;
  justify-content: center;

  -webkit-box-shadow: 0px 5px 8px 0px rgba(153,153,153,1);
  -moz-box-shadow: 0px 5px 8px 0px rgba(153,153,153,1);
  box-shadow: 0px 5px 8px 0px rgba(153,153,153,1);

  ${(props) => props.alt && css`
    border: 1x ${(props) => props.theme.colors.secondary} solid;
    color: ${(props) => props.theme.colors.secondary};

    &:hover {
      background-color: ${(props) => props.theme.colors.secondary};
      border: 1px transparent solid;
      color: white;
    }
  `}

  ${(props) => props.solidAlt && css`
    background-color: ${(props) => props.theme.colors.alternative};
    border: 1x ${(props) => props.theme.colors.secondary} solid;
    color: white;

    &:hover {
      border: 1px transparent solid;
    }
  `}
  
  ${(props) => props.noShadow && css`
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  `}

  ${(props) => props.marginBottom && css`
    margin-bottom: 25px;
  `}

  ${(props) => props.disabled && css`
    cursor: progress;
  `}

  .add-icon, .add-label {
    color: ${({theme}) => theme.colors.primary};
    font-size: 1.1em;
  }
`

export default StyledButton;

export const LinkButton = styled.a`
  background-color: ${(props) => props.solid ? props.theme.colors.primary : 'white'};
  border: ${(props) => props.withBorder ? `3px ${props.theme.colors.backgroundLightAlt} solid` : '1px transparent solid' };
  border-radius: ${(props) => props.theme.universalBorderRadius};
  color: ${(props) => props.solid ? 'white' : props.theme.textTheme.alternativeColor};
  cursor: pointer;
  //font-family: 'Ubuntu';
  font-size: ${(props) => props.theme.textTheme.fontSize.button};
  font-weight: 600;
  margin: ${(props) => props.margin ? props.margin : '0'};
  padding: ${(props) => props.padding ? props.padding : '8px 20px'};

  text-decoration: none;

  ${(props) => props.alt && css`
    border: 1x ${(props) => props.theme.colors.secondary} solid;
    color: ${(props) => props.theme.colors.secondary};

    &:hover {
      background-color: ${(props) => props.theme.colors.secondary};
      border: 1px transparent solid;
      color: white;
    }
  `}

  ${(props) => props.solidAlt && css`
    background-color: ${(props) => props.theme.colors.alternative};
    border: 1x ${(props) => props.theme.colors.secondary} solid;
    color: white;

    &:hover {
      border: 1px transparent solid;
    }
  `}

  ${(props) => props.marginBottom && css`
    margin-bottom: 25px;
  `}

`

export const HeaderButton = styled.button`
  background-color: ${(props) => props.solid ? props.theme.helpSectionTheme.primary : 'white'};
  border: ${(props) => props.solid ? '1px transparent solid;' : 'black'};
  border-radius: ${(props) => props.theme.universalBorderRadius};
  color: ${(props) => props.solid ? 'white' : 'black'};
  cursor: pointer;
  //font-family: 'Ubuntu';
  font-size: 0.8em;
  font-weight: 600;
  margin: ${(props) => props.margin ? props.margin : '0'};
  padding: ${(props) => props.padding ? props.padding : '8px 20px'};

  ${(props) => props.round && css`
    border-radius: 20px;
  `}
`

export const FaqButton = styled.button`
  background: #FFFFFF;
  box-shadow: ${ props => (!props.noShadow) && '0 9px 14px -6px rgba(0,0,0,0.50)'};
  border-radius: 15px;
  //font-family: 'Ubuntu';
  font-size: 12px;
  font-weight: normal;
  color: #303643;
  line-height: 20px;
  padding: 0.5em 1.5em;
  margin: 0.5em;
  border: ${props => props.isactive? "2px solid #FFFFF": "1px solid rgba(0,0,0,0.10)" };
  width: fit-content;
  cursor: pointer;
  
  &:hover {
    border: 2px solid #303643;
  }
`;

export const StyledIconButton = styled.button`
  border: none;
  background-color: rgba(0,0,0,0);
  border-radius: 50%;
  cursor: pointer;

  .step {
    width: 42px;
  }

  &:focus {
    outline: none;
  }
`;

export const AddButton = (props) => {
  return <StyledButton {...props}>
    <i className="material-icons-round add-icon">add_circle</i>
    <div className="add-label">{props.children}</div>
  </StyledButton>
}

export const StepButton = (props) => {
  return <StyledIconButton {...props}>
    <img className='step' src={STEPS[props.state ?? 'STEP_CURRENT']} alt='step' />
  </StyledIconButton>
}

export const ArrowButton = (props) => {
  return <StyledIconButton {...props}>
    <img src={ARROWS[props.up ? 'ARROW_TOP' : 'ARROW_BOTTOM']} alt='arrow' />
  </StyledIconButton>
}

export const CloseButton = (props) => {
  return <StyledIconButton {...props}>
    <img src={ICONS.ICON_CLOSE} alt='close icon' />
  </StyledIconButton>
}
