import styled from 'styled-components';

const FormContainer = styled.div`
  display: ${ props => props?.isVisible ? 'block' : 'none' };
  background-color: ${({theme}) => theme.colors.secondaryLight};
  border-radius: ${({theme}) => theme.universalBorderRadius};
  padding: 1em 0em;
  margin: 0 1em;
`

export default FormContainer;