import axios from 'axios'
import { ENDPOINTS } from './config'
import fileDownload from 'js-file-download'

/**
 *
 * @param {string} customerId
 * @returns
 */
export const getCustomerFiles = (customerId) =>
  axios.get(ENDPOINTS.file.find, { params: { customerId } })

/**
 *
 * @param {FormData} formData
 * @returns
 */
export const uploadFile = (formData) =>
  axios.post(ENDPOINTS.file.upload, formData)

/**
 *
 * @param {string} id - File id
 * @returns
 */
export const downloadFile = async (id, fileName) => {
  const url = `${process.env.REACT_APP_API_HOST}/api${ENDPOINTS.file.download}?id=${id}`
  await downloadUrlFile(url, fileName)
}
  
/**
 * @param {string} url Link or URL where the document is hosted
 * @param {string} filename Final name with extension of the document after downloading
 */
const downloadUrlFile = async (url, filename) => {
    const { data } = await axios.get(url, {
      responseType: 'blob'
    })
    fileDownload(data, filename)
}

