/**
 * Returns a response input as a promise
 * @param {any} response Object to return
 * @param {number} time Time to wait in miliseconds
 */
export const asyncDelayer = async (
    response,
    time = 1000
  ) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(response);
      }, time);
    });
  };
  