import { INPUT_TYPE, VALIDATOR_TYPES } from '../../containers/form'

export const CUSTOMER_FORMS = {
  FORM_DOCUMENTS: [
    {
      label: 'Six most recent bank statements',
      name: 'bank_statements_doc',
      placeholder: 'Six most recent bank statements',
      type: 'file',
      isRequired: true,
      component: 'file',
      rows: 1,
      condition: (value) => value ?? true,
      fileTypeId: 1
    },
    {
      label: 'Driver’s license for any officer with 25% or more ownership',
      name: 'driver_license_doc',
      placeholder:
        'Driver’s license for any officer with 25% or more ownership',
      type: 'file',
      isRequired: true,
      component: 'file',
      rows: 1,
      condition: (value) => value ?? true,
      fileTypeId: 2
    },
    {
      label: 'Business voided check',
      name: 'voided_check_doc',
      placeholder: 'Business voided check',
      type: 'file',
      isRequired: true,
      component: 'file',
      rows: 1,
      condition: (value) => value ?? true,
      fileTypeId: 3
    },
    {
      label: 'Lease agreement or three (3) rent payments',
      name: 'lease_rent_doc',
      placeholder: 'Lease agreement or three (3) rent payments',
      type: 'file',
      isRequired: true,
      component: 'file',
      rows: 1,
      condition: (value) => value ?? true,
      fileTypeId: 4
    },
    {
      label: 'Business or professional license',
      name: 'professional_license_doc',
      placeholder: 'Business or professional license',
      type: 'file',
      isRequired: true,
      component: 'file',
      rows: 1,
      condition: (value) => value ?? true,
      fileTypeId: 5
    },
    {
      label:
        'Tax returns for the prior fiscal year (lines of credit up to $100k) or for the last 2 years (greater than $100k)',
      name: 'tax_return_doc',
      placeholder:
        'Tax returns for the prior fiscal year (lines of credit up to $100k) or for the last 2 years (greater than $100k)',
      type: 'file',
      isRequired: true,
      component: 'file',
      rows: 1,
      condition: (value) => value ?? true,
      fileTypeId: 6
    },
    {
      label:
        'Financial statements for the prior fiscal year (lines of credit up to $100k) or for the last 2 fiscal years (greater than $100k)',
      name: 'profit_loss_doc',
      placeholder:
        'Financial statements for the prior fiscal year (lines of credit up to $100k) or for the last 2 fiscal years (greater than $100k)',
      type: 'file',
      isRequired: true,
      component: 'file',
      rows: 1,
      condition: (value) => value ?? true,
      fileTypeId: 7
    },
    {
      label: 'Operating agreements and/or corporate resolution letters',
      name: 'balance_sheet_doc',
      placeholder: 'Operating agreements and/or corporate resolution letters',
      type: 'file',
      isRequired: true,
      component: 'file',
      rows: 1,
      condition: (value) => value ?? true,
      fileTypeId: 8
    }
  ],
  FORM_INFORMATION: [
    {
      component: INPUT_TYPE.INPUT,
      name: 'name',
      label: 'Business Legal Name',
      placeholder: 'Example: xxxxxx',
      validations: [VALIDATOR_TYPES.IS_MANDATORY]
    },
    {
      component: INPUT_TYPE.INPUT,
      name: 'dba',
      label: 'Business DBA Name',
      placeholder: 'Example: xxxxxx',
      validations: [VALIDATOR_TYPES.IS_MANDATORY]
    },
    {
      component: INPUT_TYPE.string,
      name: 'startedAt',
      label: 'Date Business Started',
      type: 'date',
      placeholder: 'Example: xx/xx/xx',
      validations: [
        VALIDATOR_TYPES.IS_MANDATORY,
        VALIDATOR_TYPES.ALPHANUMERIC_SPACE
      ]
    },
    {
      component: INPUT_TYPE.INPUT,
      name: 'phone',
      label: 'Business Phone',
      placeholder: 'Example: xxxxxx',
      validations: [VALIDATOR_TYPES.IS_MANDATORY, VALIDATOR_TYPES.PHONE]
    },
    {
      component: INPUT_TYPE.INPUT,
      name: 'address:location',
      label: 'Street / Number',
      placeholder: 'Example: xxxxxx',
      validations: [
        VALIDATOR_TYPES.IS_MANDATORY,
        VALIDATOR_TYPES.ALPHANUMERIC_SPACE
      ]
    },
    {
      component: INPUT_TYPE.INPUT,
      name: 'fax',
      label: 'Business Fax',
      placeholder: 'Example: xxxxxx',
      validations: [VALIDATOR_TYPES.ALPHANUMERIC_SPACE]
    },
    {
      component: INPUT_TYPE.INPUT,
      name: 'detail:location',
      label: 'Suite/Floor',
      placeholder: 'Example: xxxxxx',
      validations: [VALIDATOR_TYPES.ALPHANUMERIC_SPACE]
    },
    {
      component: INPUT_TYPE.INPUT,
      name: 'businessType',
      label: 'Type of Business',
      placeholder: 'Example: xxxxxx',
      validations: [
        VALIDATOR_TYPES.IS_MANDATORY,
        VALIDATOR_TYPES.ALPHANUMERIC_SPACE
      ]
    },
    {
      component: INPUT_TYPE.INPUT,
      name: 'city:location',
      label: 'City',
      placeholder: 'Example: xxxxxx',
      validations: [
        VALIDATOR_TYPES.IS_MANDATORY,
        VALIDATOR_TYPES.ALPHANUMERIC_SPACE
      ]
    },
    {
      component: INPUT_TYPE.INPUT,
      name: 'state:location',
      label: 'State',
      placeholder: 'Example: xxxxxx',
      validations: [
        VALIDATOR_TYPES.IS_MANDATORY,
        VALIDATOR_TYPES.ALPHANUMERIC_SPACE
      ]
    },
    {
      component: INPUT_TYPE.INPUT,
      name: 'zipCode:location',
      label: 'Zip Code',
      placeholder: 'Example: xxxxxx',
      validations: [VALIDATOR_TYPES.IS_MANDATORY, VALIDATOR_TYPES.ZIP_CODE]
    },
    {
      component: INPUT_TYPE.SELECT,
      name: 'addressType:location',
      label: 'Is the address above the mailing or the office address?',
      validations: [
        VALIDATOR_TYPES.IS_MANDATORY,
        VALIDATOR_TYPES.ALPHANUMERIC_SPACE
      ],
      options: [
        { label: 'Mailing', value: 'mailing' },
        { label: 'Address', value: 'address' }
      ]
    },
    {
      component: INPUT_TYPE.INPUT,
      name: 'incorporationState',
      label: 'State of Incorporation',
      placeholder: 'Example: xxxxxx',
      validations: [
        VALIDATOR_TYPES.IS_MANDATORY,
        VALIDATOR_TYPES.ALPHANUMERIC_SPACE
      ]
    },
    {
      component: INPUT_TYPE.INPUT,
      name: 'employees',
      label: 'Number of Employees',
      placeholder: 'Example: 1',
      validations: [
        VALIDATOR_TYPES.IS_MANDATORY,
        VALIDATOR_TYPES.POSITIVE_NUMBER
      ]
    },
    {
      component: INPUT_TYPE.INPUT,
      name: 'website',
      label: 'Website',
      placeholder: 'Example: xxxxxx',
      validations: [VALIDATOR_TYPES.IS_MANDATORY]
    }
  ],
  FORM_FINANCIAL_INFORMATION: [
    {
      component: INPUT_TYPE.INPUT,
      name: 'clients',
      title: 'Write the clients separated by , (comma) and without space',
      label: 'Top Clients',
      placeholder: 'Example: xxx,xxx,xx',
      validations: [
        VALIDATOR_TYPES.IS_MANDATORY,
        VALIDATOR_TYPES.ALPHANUMERIC_COMMA
      ]
    },
    {
      component: INPUT_TYPE.INPUT,
      name: 'cashSalesPercentage:statement',
      label: '% of Cash Sales',
      placeholder: 'Example: 100',
      validations: [VALIDATOR_TYPES.IS_MANDATORY, VALIDATOR_TYPES.PERCENTAGE]
    },
    {
      component: INPUT_TYPE.INPUT,
      name: 'mainSupplier:statement',
      label: 'Main Supplier',
      placeholder: 'Example: xxxxxxxx',
      validations: [
        VALIDATOR_TYPES.IS_MANDATORY,
        VALIDATOR_TYPES.ALPHANUMERIC_SPACE
      ]
    },
    {
      component: INPUT_TYPE.INPUT,
      name: 'internationalSalesPercentage:statement',
      label: '% of International Sales',
      placeholder: 'Example: 100',
      validations: [VALIDATOR_TYPES.IS_MANDATORY, VALIDATOR_TYPES.PERCENTAGE]
    },
    {
      component: INPUT_TYPE.INPUT,
      name: 'monthlySales:statement',
      label: 'Monthly Gross Sales',
      placeholder: 'Example: 1000',
      validations: [
        VALIDATOR_TYPES.IS_MANDATORY,
        VALIDATOR_TYPES.POSITIVE_NUMBER
      ]
    },
    {
      component: INPUT_TYPE.INPUT,
      name: 'creditSalesPercentage:statement',
      label: '% of Credit Sales',
      placeholder: 'Example: 100',
      validations: [VALIDATOR_TYPES.IS_MANDATORY, VALIDATOR_TYPES.PERCENTAGE]
    },
    {
      component: INPUT_TYPE.INPUT,
      name: 'yearlySales:statement',
      label: 'Yearly Gross Sales',
      placeholder: 'Example: 1000',
      validations: [
        VALIDATOR_TYPES.IS_MANDATORY,
        VALIDATOR_TYPES.POSITIVE_NUMBER
      ]
    },
    {
      component: INPUT_TYPE.INPUT,
      name: 'nationalSalesPercentage:statement',
      label: '% of National Sales',
      placeholder: 'Example: 100',
      validations: [VALIDATOR_TYPES.IS_MANDATORY, VALIDATOR_TYPES.PERCENTAGE]
    },
    {
      component: INPUT_TYPE.INPUT,
      name: 'projectionNextYearlySales:statement',
      label: "Projection for next year's Gross Sales",
      placeholder: 'Example: 100',
      validations: [
        VALIDATOR_TYPES.IS_MANDATORY,
        VALIDATOR_TYPES.POSITIVE_NUMBER
      ]
    },
    {
      component: INPUT_TYPE.INPUT,
      name: 'inventory:statement',
      label: 'How much $ in Inventory',
      placeholder: 'Example: 1000',
      validations: [
        VALIDATOR_TYPES.IS_MANDATORY,
        VALIDATOR_TYPES.POSITIVE_NUMBER
      ]
    },
    {
      inputType: INPUT_TYPE.INPUT,
      name: 'averageTicketItem:statement',
      label: 'Average Ticket Item',
      placeholder: 'Example: 1000',
      validations: [
        VALIDATOR_TYPES.IS_MANDATORY,
        VALIDATOR_TYPES.POSITIVE_NUMBER
      ]
    },
    {
      component: INPUT_TYPE.SELECT,
      name: 'legalIssues:statement',
      label: 'Any Open Tax Liens, Bk, Judgments',
      validations: [
        VALIDATOR_TYPES.IS_MANDATORY,
        VALIDATOR_TYPES.ALPHANUMERIC_SPACE
      ],
      options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false }
      ]
    },
    {
      component: INPUT_TYPE.SELECT,
      name: 'legalIssuesDischarged:statement',
      label:
        'IF YES, were the Tax Liens, Bankruptcy, and Judgments discharged? ',
      placeholder: 'Example: xxxxxx',
      validations: [VALIDATOR_TYPES.ALPHANUMERIC_SPACE],
      options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false }
      ]
    },
    {
      component: INPUT_TYPE.INPUT,
      name: 'legalIssuesDischargedAt:statement',
      label: 'IF YES, what is the date of the discharge?',
      placeholder: 'Example: xxxxxx',
      validations: [VALIDATOR_TYPES.ALPHANUMERIC_SPACE],
      type: 'date'
    }
  ],
  FORM_LOAN_REQUEST: [
    {
      component: INPUT_TYPE.INPUT,
      name: 'amount:loanRequest',
      label: 'Amount Requested',
      placeholder: 'Example: 1000',
      validations: [
        VALIDATOR_TYPES.IS_MANDATORY,
        VALIDATOR_TYPES.POSITIVE_NUMBER
      ]
    },
    {
      component: INPUT_TYPE.INPUT,
      name: 'fundsUse:loanRequest',
      label: 'Use of funds',
      placeholder: 'Example: xxxxxxxx',
      validations: [
        VALIDATOR_TYPES.IS_MANDATORY,
        VALIDATOR_TYPES.ALPHANUMERIC_SPACE
      ]
    }
  ],
  FORM_ADD_OWNER: [
    {
      component: INPUT_TYPE.INPUT,
      name: 'name:owner',
      label: "Business Owner's Name",
      placeholder: 'Example: xxxxxx',
      validations: [VALIDATOR_TYPES.IS_MANDATORY]
    },
    {
      component: INPUT_TYPE.INPUT,
      name: 'email:owner',
      label: 'Email',
      placeholder: 'Example: xxxxxx',
      validations: [VALIDATOR_TYPES.IS_MANDATORY, VALIDATOR_TYPES.EMAIL]
    },
    {
      component: INPUT_TYPE.INPUT,
      name: 'address:owner.location',
      label: 'Street / Number',
      placeholder: 'Example: xxxxxx',
      validations: [VALIDATOR_TYPES.IS_MANDATORY]
    },
    {
      component: INPUT_TYPE.INPUT,
      name: 'mobile:owner',
      label: 'Mobile Phone',
      placeholder: 'Example: xxxxxx',
      validations: [VALIDATOR_TYPES.IS_MANDATORY, VALIDATOR_TYPES.PHONE]
    },
    {
      component: INPUT_TYPE.INPUT,
      name: 'detail:owner.location',
      label: 'Suite/Floor',
      placeholder: 'Example: xxxxxx',
      validations: VALIDATOR_TYPES.TEXT_FIELD
    },
    {
      component: INPUT_TYPE.INPUT,
      name: 'title:owner',
      label: 'Title',
      placeholder: 'Example: xxxxxx',
      validations: [VALIDATOR_TYPES.IS_MANDATORY]
    },
    {
      component: INPUT_TYPE.INPUT,
      name: 'city:owner.location',
      label: 'City',
      placeholder: 'Example: xxxxxx',
      validations: [VALIDATOR_TYPES.IS_MANDATORY]
    },
    {
      component: INPUT_TYPE.INPUT,
      name: 'state:owner.location',
      label: 'State',
      placeholder: 'Example: xxxxxx',
      validations: [VALIDATOR_TYPES.IS_MANDATORY]
    },
    {
      component: INPUT_TYPE.INPUT,
      name: 'zip:owner.location',
      label: 'Zip Code',
      placeholder: 'Example: xxxxxx',
      validations: [VALIDATOR_TYPES.IS_MANDATORY, VALIDATOR_TYPES.ZIP_CODE]
    },
    {
      component: INPUT_TYPE.INPUT,
      name: 'ownershipPercentage:owner',
      label: '% of Ownership',
      placeholder: 'Example: 100',
      validations: [VALIDATOR_TYPES.IS_MANDATORY, VALIDATOR_TYPES.PERCENTAGE]
    },
    {
      component: INPUT_TYPE.INPUT,
      name: 'birthdate:owner',
      label: 'Date of birth',
      type: 'date',
      placeholder: 'Example: xx/xx/xx',
      validations: [
        VALIDATOR_TYPES.IS_MANDATORY,
        VALIDATOR_TYPES.ALPHANUMERIC_SPACE
      ]
    },
    {
      component: INPUT_TYPE.INPUT,
      name: 'ssn:owner',
      label: 'SSN',
      placeholder: 'Example: xxxxxx',
      validations: [VALIDATOR_TYPES.IS_MANDATORY]
    }
  ],
  FORM_OWNER_LOCATION: []
}
