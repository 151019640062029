import styled from 'styled-components'

const Layout = styled.div`
  background-color: ${(props) => props.theme.colors.backgroundLightAlt};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 25px 20px 20px 20px;

  .main-container {
    width: 100%;
    max-width: 980px;
    margin: 0 auto;
  }
  .loading-customer {
    width: 500px;
    position: absolute;
    margin: auto;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
    span {
      width: 20px;
      height: 20px;
    }
  }
  .form-container {
    padding: 0 5%;
    margin: auto;
  }

  .card-container {
    box-shadow: 0 22px 32px 0 rgba(201, 172, 173, 0.52);
    border-radius: 10px;
    width: 502px;
    height: 200px;
    margin: 5rem auto;
    h3 {
      margin-top: 0rem;
      text-align: center;
    }
    .section {
      background-color: #d50032;
      border-radius: 10.8px 10.8px 0 0;
      height: 8px;
      color: white;
    }
    .material-icons-outlined {
      display: block;
      justify-content: center;
      margin: 2rem;
      font-size: 50px;
      color: #d50032;
    }
    .center {
      display: flex;
      top: 1rem;
      margin: auto;
      width: 150px;
    }
  }
`

export default Layout
