import { THEME } from '@keoworld/gbl-ui-kit'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import App from './app'
//import './index.css'
import '@keoworld/gbl-ui-kit/styles/index.css'
import ContentProvider from './providers/content-provider'
import UserProvider from './providers/user-provider'
import theme from './theme/theme'
import { setUpAxios } from './services/config'
import { CustomerProvider } from './providers/customer-provider'
import { AlertProvider } from './containers/alert'

setUpAxios()

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <UserProvider>
        <CustomerProvider>
          <ContentProvider>
            <ThemeProvider theme={{ ...theme, ...THEME }}>
              <AlertProvider>
                <App />
              </AlertProvider>
            </ThemeProvider>
          </ContentProvider>
        </CustomerProvider>
      </UserProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)
