import styled from 'styled-components';

const TextArea = styled.textarea`
  width: 100%;
  height: auto;
  background-color: ${({ theme }) => theme.colors.backgroundLight};
  border: ${({ warning, theme }) =>
    warning
      ? `1px solid ${theme.colors.red}`
      : `1px solid ${theme.colors.grey}`};

  margin-bottom: 0.5em;
  border-radius: 4.35px;
  padding: 0.5em 1em;

  //font-family: ${({ theme }) => theme.textTheme.fontFamily};

  &:focus {
    border: 1.5px solid ${({ theme }) => theme.colors.secondaryLight};
  }
`;

export default TextArea;
