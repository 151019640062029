export const FILE_STAGES = {
  OnBoarding: "OnBoarding", 
  Underwritting: "Underwritting", 
  Disbursement: "Disbursement", 
  Collection: "Collection" 
}

export const FILE_STATES = {
  DRAFT: 'Draft',
  PENDING: 'Pending',
  APPROVED: 'Approved',
  REJECTED: 'Rejected'
}

export const CUSTOMER_WF_STATES = {
  cust_externalOnBoarding: {
    transition: {
      event: 'SEND_FORM',
      wfState: 'cust_inOperationsReview',
      wfStage: 'OnBoarding'
    }
  }
}