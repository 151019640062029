import { Icon } from '@keoworld/gbl-ui-kit'
import styled from 'styled-components'
/**
 * Return <Table /> Component
 * @param {Object[]} headers
 * @param {string} headers.label
 * @param {Array[]} rows
 * @param {Object[]} rows.columns
 * @param {string} rows.columns.value
 */
const Table = ({ headers, rows, onEditClick, onRemoveClick }) => {
  return (
    <StyledTable>
      <thead>
        <tr>
          {headers.map((header, i) => {
            return <th key={header.label + i}>{header.label}</th>
          })}
        </tr>
      </thead>
      <tbody>
        {!rows.isEmpty &&
          Object.values(rows).map((row, j) => (
            <tr key={`row-${j}`}>
              <td>{row.name}</td>
              <td>{row.ownershipPercentage}</td>
              <td>{row.title}</td>
              <td>
                <button
                  className={!row.id ? 'btn btn-error' : "btn btn-edit" }
                  disabled={!row.id}
                  onClick={() => onEditClick(row)}
                >
                  <Icon name="edit" type="outlined" />
                </button>
                <button
                  className="btn btn-remove"
                  onClick={() => onRemoveClick(row, j)}
                >
                  <Icon name="delete" type="outlined" />
                </button>
              </td>
            </tr>
          ))}
      </tbody>
    </StyledTable>
  )
}

const StyledTable = styled.table`
  border-collapse: collapse;
  text-align: left;
  width: 100%;
  margin: 1em 0;
  th {
    span {
      text-align: center;
    }
  }
  .btn {
    border-radius: 0.25rem;
    border: none;
  }
  .btn-error{
    cursor: not-allowed;
    background-color: white !important;
  }
  .btn-remove {
    color: #dc3545;
    margin-left: 0.3rem;
  }
  .btn-edit {
    color: #007bff;
  }
  button:hover {
    transform: scale(1.06) translateZ(0px) !important;
    background-color: #007bff42;
  }
  button {
    span{
      padding: 5px 2px 5px 2px;
    }
  }

  td {
    text-align: center;
  }
  thead {
    tr {
      th {
        background-color: ${(props) => props.theme.colors.secondary};
        border-left: 1px solid ${(props) => props.theme.colors.backgroundLight};
        color: black;
        font-weight: 500;
        padding: 15px;
        position: -webkit-sticky; // this is for all Safari (Desktop & iOS), not for Chrome
        position: sticky;
        text-align: center;
        top: 0;
        white-space: nowrap;

        span {
          align-items: start;
          display: flex;
          flex-direction: column;

          &.empty-filter {
            height: 40px;
          }
        }

        &:first-child {
          border-top-left-radius: 5px;
        }

        &:last-child {
          border-top-right-radius: 5px;
          border-right: 1px solid
            ${(props) => props.theme.colors.backgroundLight};
        }
      }
    }
  }
`

export default Table
