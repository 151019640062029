import Modal from '../../components/modal';
import styled from 'styled-components';
import { ICONS } from '../../assets/images';

/**
 * 
 * @param {Object} props
 * @param {boolean} props.openModal 
 * @param {Function} props.setOpenModal
 */
const FinalModal = ({openModal, setOpenModal, onSubmit }) => {
  return <Modal modalOpen={openModal}>
      <ModalBody>
        <h2>Congrats!</h2>
        <img className="icon" src={ICONS.ICON_TICK} alt="tick" />
        <p>
          You have successfully completed your application process, a member of our team will contact you shortly.  
        </p>
      </ModalBody>
    </Modal>
};

export default FinalModal;

const ModalBody = styled.div`
  padding: 1em 2em 2em 2em;
  text-align: center;
`;