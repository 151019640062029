const validators = {
  mandatory: (value) => Boolean(value),
  email: (value) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value),
  numbers: (value) =>
    value !== '' &&
    value.trim() === value &&
    !isNaN(value) &&
    /^(0|[1-9]\d*)(\.\d+)?$/.test(value),
  alphaNum: (value) =>
    /^[a-zA-ZÀ-ÿ0-9\u00f1\u00d1]*[a-zA-ZÀ-ÿ0-9\u00f1\u00d1]+$/.test(value),
  string: (value) =>
    /^[a-zA-ZÀ-ÿ\u00f1\u00d1]*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/.test(value),
  phone: (value) => /^[0-9]{10}$/.test(value),
  zipCode: (value) => value !== '' && /^[0-9]{5}$/.test(value),
  lada: (value) => value !== '' && /^[0-9]{1,3}$/.test(value),
  percentage: (value) =>
    value !== '' && value >= 0 && /\b(0*(?:[0-9][0-9]?|100))\b/.test(value),
  any: (value) => Boolean(value),
  rfc: (value) => /^[a-zA-ZÀ-ÿ0-9\u00f1\u00d1]{1,13}$/.test(value),
  numberInterior: (value) => /^[a-zA-ZÀ-ÿ0-9\u00f1\u00d1]{5}$/.test(value),
  numberExterior: (value) => value !== '' && /^[0-9]{5}$/.test(value),
  score: (value) => value !== '' && /^[0-9]{7}$/.test(value),
  alphaNumSpace: (value) =>
    /^[-_ a-zA-ZÀ-ÿ0-9\u00f1\u00d1]*[a-zA-ZÀ-ÿ0-9\u00f1\u00d1]+$/.test(value),
  alphaNumWithComma: (value) =>
    /^([ a-zA-ZÀ-ÿ0-9\u00f1\u00d1],?)*([a-zA-ZÀ-ÿ0-9\u00f1\u00d1],?)+$/.test(
      value
    ),
  term: (value) => value !== '' && /^[0-9]{2}$/.test(value),
  stringSpace: (value) =>
    /^[-_ a-zA-ZÀ-ÿ\u00f1\u00d1]*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/.test(value),
  decimal: (value) =>
    value !== '' && /^(0|[1-9]\d*)?(\.\d+)?(?<=\d)$/.test(value),
  positivesNumbers: (value) => value !== '' && value > 0 && !isNaN(value)
}

/**
 * @param {string} value
 * @param {string} type
 * @returns
 */
export const validateValue = (value, type = 'any') => {
  if (typeof value === 'string') {
    value = value.trim()
    return validators[type](value)
  } else {
    return validators[type](value)
  }
}

export const validateForm = (
  fullSchema,
  formValues,
  isValidating = () => true
) =>
  fullSchema.reduce((acc, { name, validations = [] }) => {
    const invalidCriteria = validations.filter((itm) => {
      if (itm === 'mandatory')
        return !validateValue(formValues[name], itm) && isValidating(name)
      else {
        if (
          formValues[name] !== undefined &&
          formValues[name] !== '' &&
          formValues[name] !== null
        )
          return !validateValue(formValues[name], itm) && isValidating(name)
        else return false
      }
    })
    const hasInvalidCriteria = invalidCriteria.length > 0
    if (hasInvalidCriteria) return { ...acc, [name]: invalidCriteria }
    return acc
  }, {})
