import styled from 'styled-components';

const DropdownTitle = styled.div`
  display: flex;
  //border-bottom: 1px ${(props) => props.theme.colors.grey} solid;
  margin-bottom: 10px;
  justify-content: space-between;

  -webkit-box-shadow: 0px 7px 10px -10px rgba(153,153,153,1);
  -moz-box-shadow: 0px 7px 10px -10px rgba(153,153,153,1);
  box-shadow: 0px 7px 10px -10px rgba(153,153,153,1);
`;

export default DropdownTitle;