import styled from 'styled-components'

const Input = styled.input`
  width: 100%;
  height: 3em;
  background: ${({ theme }) => theme.colors.backgroundLight};
  border: ${({ theme, warning }) =>
    warning
      ? `1px solid ${theme.colors.red}`
      : `1px solid ${theme.colors.grey}`};

  margin-bottom: 0.5em;
  border-radius: 4.35px;
  padding: 0 1em;
  //font-family: ${({ theme }) => theme.textTheme.fontFamilyAlternative};

  &:focus {
    border: 1.5px solid ${({ theme }) => theme.colors.secondaryLight};
  }
`

const StyledInputFile = styled.div`
  border: 1px grey solid;
  border-radius: 5px;
  padding: 0.5em;
  display: flex;
  min-width: 20em;

  .label {
  }

  .input-area {
    display: flex;

    .input-file {
      min-width: 10em;
    }
    .file-label  {
      width: 100%;
      height: 41px;
      color: #717171;
      background: ${({ theme }) => theme.colors.backgroundLight};
      border: ${({ theme, warning }) =>
        warning
          ? `1px solid ${theme.colors.red}`
          : `1px solid ${theme.colors.grey}`};
      //font-family: ${({ theme }) => theme.textTheme.fontFamilyAlternative};
      font-size: 0.9em;
      margin: 0 0 0 0.5em;
      border-radius: 4.35px;
      padding: 0.8em 1em;
    }
  }
`

const InputFileStyles = {
  input: {
    height: '46px',
    width: '300px',
    opacity: '0',
    zIndex: '1',
    position: 'absolute',
    display: 'none',
    cursor: 'pointer'
  },
  icon: { fontSize: '20px', lineHeight: '1.5' },
  label: {
    width: '100%',
    overflow: 'hidden',
    textAlign: 'center',
    border: 'solid 0px #979797',
    backgroundColor: '#E6E8ED',
    borderRadius: '5px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    boxShadow: '0px 5px 8px 0px rgba(153,153,153,1)',
    cursor: 'pointer'
  }
}

/**
 * Return <InputFile /> Component
 * @param {Function} handleInputFile - Function to handle input file, will pass event in params
 * @param {string} label - Value to render in label
 */
export const InputFile = ({
  handleInputFile,
  label,
  fileLabel = '',
  ...props
}) => {
  return (
    <StyledInputFile>
      <div className='label'>
        <p>{fileLabel}</p>
        <p className='file-label'>
          {label ? label.slice(0, 32) : ' Select a file '}
        </p>
      </div>
      <div className='input-area'>
        <div className='input-file'>
          <Input
            {...props}
            id={`file-${props.name}`}
            type='file'
            placeholder='input file'
            accept='.pdf,image/*,.doc,.docx,.xlsx,.xls,.csv'
            onChange={(e) => handleInputFile(e)}
            style={InputFileStyles.input}
          />
          <label htmlFor={`file-${props.name}`} style={InputFileStyles.label}>
            <div>Upload</div>
            <i className='material-icons-round' style={InputFileStyles.icon}>
              file_upload
            </i>
          </label>
        </div>
      </div>
    </StyledInputFile>
  )
}

export default Input
