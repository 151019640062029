import styled from 'styled-components';

const ButtonGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1em 0;
  height: 3em;
  padding: 0 1em;
`;

export default ButtonGroup;