import React, { useState, useContext } from 'react';
import Popover from '@material-ui/core/Popover';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import IMAGES from '../assets/images';
import { StyledLink } from '../components/anchor';
import Button from '../components/button';
import ROUTES from '../utilities/routes';

import { UserContext } from '../providers/user-provider';

/**
 * 
 * @param {location} location React router location 
 */
const Header = (props) => {
  const {location} = props
  const isCustomer = (location.pathname === ROUTES.CUSTOMER);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const user = useContext(UserContext)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return <HeaderContainer isCustomer={isCustomer} >
    <StyledLink noShadow noPadding withIcon>
      <Link to='/'>
        {location.pathname !== ROUTES.LOGIN && !isCustomer && 
          <i className='material-icons-round'>
            arrow_back_ios
          </i>
        }
        <img src={IMAGES.KEO_ISOLOGO} alt='Keo World' />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        {/*<img src={IMAGES.AMEX_LOGO} style={{height:'50px'}} alt='amex' />*/}
      </Link>
    </StyledLink>
    {location.pathname !== ROUTES.LOGIN &&
      <h2>{user?.currentCRM?.displayName}</h2>
    }
    <div className='control'>
      <Button noShadow withIcon onClick={handleClick}>
        {isCustomer ? 'Customer' : 'User'}
        <i className='material-icons-round'>
          arrow_drop_down
        </i>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className='popover-content'>
          <Button
            alt
            noShadow
            popOverButton
            onClick={() => user.SignOut()}
          >
            Sign Out
          </Button>
        </div>
      </Popover>
    </div>
  </HeaderContainer>
}

export default Header;

const HeaderContainer = styled.div`
    align-items: center;
    background-color: ${(props) => props.theme.colors.primary.dark.backgroundColor};
    display: flex;
    height: 70px;
    justify-content: space-between;
    padding: 15px 20px;
    position: relative;
    width: 100%;

    a {
      background-color: ${(props) => props.theme.colors.primary.dark.backgroundColor};
    }
    img {
      height: 40px;
    }
            
    span {
        color: ${(props) => props.theme.colors.primary};
        margin-right: 25px;
        font-size: 20px;
    }
    
    button {
        width: auto;
    }

    h2 {
      margin: 0;
      line-height: 40px;
    }
    
    -webkit-box-shadow: 0px 5px 3px 0px rgba(153,153,153,1);
    -moz-box-shadow: 0px 5px 3px 0px rgba(153,153,153,1);
    box-shadow: 0px 5px 3px 0px rgba(153,153,153,1);
`