import { Button } from '@keoworld/gbl-ui-kit'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { default as LocalButton } from '../../components/button'
import ButtonGroup from '../../containers/button-group'
import { getCustomerInfo } from '../../services/customer'
import { getCustomerFiles } from '../../services/files'
import { CUSTOMER_FORMS } from '../../utilities/constants/form_skemas'
import {
  formatInitialForm,
  formatInitialOwners
} from '../../utilities/functions/format-form'
import { CustomerContext } from '../../providers/customer-provider'
import { useContext } from 'react'
import { AlertContext } from '../../containers/alert'

/**
 *
 * @param {Object} props
 * @param {Function} props.fullForm
 * @param {Function} props.onReturn
 * @param {Function} props.onNext
 */
const Review = ({ onReturn, onNext }) => {
  const [customerForm, setCustomerForm] = useState({})
  const [customerFiles, setCustomerFiles] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const { customer } = useContext(CustomerContext)
  const { setAlert } = useContext(AlertContext)

  useEffect(() => {
    setAlert({
      title: 'Information',
      label:
        'Remember to save, verify and complete the entire form before proceeding.',
      type: 'info'
    })
    const fetchCustomerInfo = async () => {
      try {
        const { data } = await getCustomerInfo(customer?.customerId)
        const { formInformation, formFinancialInformation, formLoanRequest } =
          formatInitialForm(data)
        const formOwners = formatInitialOwners(data?.owners)
        setCustomerForm({
          formInformation,
          formFinancialInformation,
          formLoanRequest,
          formOwners
        })
      } catch (err) {
        console.log(err.message ?? 'Unknown error.')
      }
      setIsLoading(false)
    }
    fetchCustomerInfo()
  }, [customer, setAlert])

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const { data } = await getCustomerFiles(customer?.customerId)
        const customerFiles = data.reduce((acc, itm) => {
          const key = itm?.name?.split('.')[0]
          return { ...acc, [key]: itm }
        }, {})
        setCustomerFiles(customerFiles)
      } catch (err) {
        console.log(err.message ?? 'Unknown error.')
      }
      setIsLoading(false)
    }
    fetchFiles()
  }, [customer])

  useEffect(() => {
    const interval = setInterval(() => {
      setAlert(undefined)
    }, 8000)
    return () => clearInterval(interval)
  }, [setAlert])

  return (
    <div className='form-container'>
      <h2>Review</h2>
      <RevisionContainer>
        {isLoading && <div>loading</div>}
        {!isLoading && customerForm?.formInformation && (
          <>
            <div className='grid'>
              <div className='col-1'>
                <h3>Customer Profile</h3>
                <div className='content'>
                  {CUSTOMER_FORMS.FORM_INFORMATION.map(
                    (field, idx) =>
                      checkNullable(
                        customerForm?.formInformation[field.name]
                      ) && (
                        <p key={`bank-${idx}`}>
                          <strong>{field.label + ': '}</strong>
                          {customerForm?.formInformation[field.name]}
                        </p>
                      )
                  )}
                </div>
              </div>
              <div className='col-2'>
                <h3>Business Owner Profile</h3>
                <div className='content'>
                  {customerForm?.formOwners?.map((owner, idx) => (
                    <div key={idx}>
                      {CUSTOMER_FORMS.FORM_ADD_OWNER.map((field, idx) => {
                        const skemaName = field.name.split(':')[0]
                        return (
                          <p className='owner-row'>
                            <strong>{`${field.label}: `}</strong>
                            {`${owner[skemaName]}`}
                          </p>
                        )
                      })}
                      <br />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className='grid'>
              <div className='col-1'>
                <h3>Financial Information</h3>
                <div className='content'>
                  {CUSTOMER_FORMS.FORM_FINANCIAL_INFORMATION.map(
                    (field, idx) =>
                      checkNullable(
                        customerForm?.formFinancialInformation[field.name]
                      ) && (
                        <p key={`contact-${idx}`}>
                          <strong>{field.label + ': '}</strong>
                          {customerForm?.formFinancialInformation[
                            field.name
                          ] === 'true'
                            ? 'Yes'
                            : customerForm?.formFinancialInformation[
                                field.name
                              ] === 'false'
                            ? 'No'
                            : customerForm?.formFinancialInformation[
                                field.name
                              ]}
                        </p>
                      )
                  )}
                </div>
              </div>
              <div className='col-2'>
                <h3>Loan Request</h3>
                <div className='content'>
                  {CUSTOMER_FORMS.FORM_LOAN_REQUEST.map(
                    (field, idx) =>
                      checkNullable(
                        customerForm?.formLoanRequest[field.name]
                      ) && (
                        <p key={`bank-${idx}`}>
                          <strong>{field.label + ': '}</strong>
                          {customerForm?.formLoanRequest[field.name]}
                        </p>
                      )
                  )}
                </div>
              </div>
            </div>
            <div className='grid'>
              <div className='col-1'>
                <h3>Documents</h3>
                <div className='content'>
                  {CUSTOMER_FORMS.FORM_DOCUMENTS.map(
                    (field, idx) =>
                      customerFiles[field.name] && (
                        <p key={`bank-${idx}`}>
                          <strong>{field.label + ': '}</strong>
                          {customerFiles[field.name]?.name}
                        </p>
                      )
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </RevisionContainer>
      <ButtonGroup>
        <LocalButton onClick={() => onReturn()}>Back</LocalButton>
        <Button solid onClick={() => onNext()}>
          Continue
        </Button>
      </ButtonGroup>
    </div>
  )
}

export default Review

const checkNullable = (value) => value || value === 0

const RevisionContainer = styled.div`
  .grid {
    display: grid;
    grid-template-columns: 50% 50%;
    .col-1 {
      grid-column: 1;
    }
    .col-2 {
      grid-column: 2;
    }
  }

  .content {
    background-color: ${({ theme }) => theme.colors.secondaryLight};
    border-radius: ${({ theme }) => theme.universalBorderRadius};
    padding: 1em;
    margin: 0 1em;
  }

  .owner-row {
    margin: 0 0;
  }

  p {
    margin: 0 0;
  }
`
