import { Fragment } from 'react'; 
import { StepButton } from '../components/button';
import styled from 'styled-components';

const STEPS_STATES = {
  CURRENT: 'STEP_CURRENT',
  VISITED: 'STEP_VISITED',
  UNVISITED: 'STEP_UNVISITED'
}

const getState = (idx, current) => {
  if (idx === current)
      return STEPS_STATES.CURRENT;
  if (idx < current)
      return STEPS_STATES.VISITED;
  return STEPS_STATES.UNVISITED;
}

/**
 * Return <Stepper /> component
 * @param {number} current  - Index of current step
 * @param {Function} setCurrStep - Function to handle set current step
 * @param {Object[]} steps_info - Array with the information of each step
 * @param {string} steps_info.label - Text to show in step
 */
const Stepper = ({ current, setCurrStep, steps_info}) => {

  return <StyledStepper>
    { 
      steps_info.map( (step, idx) =>
        <Fragment  key={`cont-stp-${idx}`} >
          <div className='step'>
            <span>{step.label}</span>
            <StepButton 
              key={`stp-${idx}`} 
              state={getState(idx, current)}
              onClick={()=>setCurrStep(idx)} />
          </div>
          {(idx < steps_info.length - 1) && <div className="line" />}
        </Fragment>
      )
    }
  </StyledStepper>    
}

export default Stepper;

const StyledStepper = styled.div`
  display: flex;    
  align-content: center;
  justify-content: center;
  margin-bottom: 1em;

  span {
    font-weight: bold;
    margin: 4px 0;
  }

  button {
    display: flex;    
    align-content: center;
    justify-content: center;
  }
  
  .step {
    display: flex;    
    align-content: center;
    justify-content: center;
    flex-direction: column;
  }

  .line {
    height: 52px;
    border-bottom: 2px solid grey;
    width: 50px;
  }
`