import { Button } from '@keoworld/gbl-ui-kit'
import { useState } from 'react'
import styled from 'styled-components'
import { CloseButton } from '../../components/button'
import Modal from '../../components/modal'
import ButtonGroup from '../../containers/button-group'
import Form from '../../containers/form'

const FormModal = ({
  openModal,
  setOpenModal,
  formSkema,
  form,
  setForm,
  onSubmit
}) => {
  const [formError, setFormError] = useState({})
  const [isValidating, setIsValidating] = useState(false)

  const handleSubmit = async () => {
    setIsValidating(true)
    const hasErrors = Object.entries(formError).length === 0 ? false : true

    if (!hasErrors) {
      onSubmit()
      setIsValidating(false)
    }
  }

  return (
    <Modal modalOpen={openModal}>
      <ModalBody>
        <div className='close-btn'>
          <CloseButton onClick={() => setOpenModal(false)} />
        </div>
        <Form
          skema={formSkema}
          controller={form}
          setController={setForm}
          formErrors={formError}
          setFormErrors={setFormError}
          isValidating={isValidating}
        />
        <ButtonGroup>
          <div />
          <Button solid onClick={() => handleSubmit()}>
            {form.id ? 'Update' : 'Add'}
          </Button>
        </ButtonGroup>
      </ModalBody>
    </Modal>
  )
}

export default FormModal

const ModalBody = styled.div`
  padding: 1em 2em;

  .close-btn {
    display: flex;
    justify-content: flex-end;
  }
`
