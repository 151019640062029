import styled from 'styled-components'
import InputFile from '../components/inputFile'
import { downloadFile } from '../services/files'

/**
 * @param {object} props 
 * @param {object} props.controller 
 * @param {function} props.setController 
 * @param {object} props.skema 
 * @returns 
 */
const FileForm = ({
  controller,
  setController, 
  skema 
}) => {
  const handleInputChange = ({name, fileTypeId}, files) => {
    const file = files[0]
    file.fileTypeId = fileTypeId
    
    setController((form) => ({
      ...form,
      [name]: file
    }))
  }
  
  const handleDownload =  async (name) => {
    const file = controller[name]
    try {
      await downloadFile(file?.id, file?.name)
    } catch (err) {
      console.log(err?.message ?? 'Unknown error on download.')
    }
  }

  return <FileFormStyled>
    {
      skema.map( ({
          label,
          name,
          fileTypeId
        }, idx) => 
          <InputFile
            key={`file-${idx}`}
            label={label}
            file={controller[name]}
            name={name}
            onChange={(e) => handleInputChange({name,fileTypeId}, e)}
            onClickDownload={()=>handleDownload(name)}
        /> 
      )
    }
  </FileFormStyled>
}

const FileFormStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export default FileForm