import styled, { css } from 'styled-components';

export const StyledLink = styled.span`
  a {
    align-items: center;
    background-color: ${(props) => props.solid ? props.theme.colors.primary : 'white'};
    border: none;
    border-radius: ${(props) => props.theme.universalBorderRadius};
    color: ${(props) => props.solid ? 'white' : props.theme.colors.primary};
    cursor: pointer;
    //font-family: 'Source Sans Pro';
    text-align: center;
    font-size: ${(props) => props.theme.textTheme.fontSize.normal};
    height: 40px;
    justify-content: center;
    padding: 8px 10px 8px 20px;
    text-decoration: none;

    -webkit-box-shadow: 0px 5px 8px 0px rgba(153,153,153,1);
    -moz-box-shadow: 0px 5px 8px 0px rgba(153,153,153,1);
    box-shadow: 0px 5px 8px 0px rgba(153,153,153,1);

    i {
      font-size: 24px;
      margin-left: 10px;
    }

    ${(props) => props.popOverButton && css`
      color: ${(props) => props.theme.colors.secondary};
      justify-content: left;


      &:first-child {
        border-radius: 0;
        border-bottom: 1px ${(props) => props.theme.colors.secondary} solid;
      }
    `}

    ${(props) => props.noShadow && css`
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    `}

    ${(props) => props.noPadding && css`
      padding: 0;
    `}
  }
`;

export const MainMenuLink = styled.span`
  overflow: hidden;

  &.active {
    a {
      border-right: 1px ${(props) => props.theme.textTheme.primaryColorLight} solid;
      background-color: ${(props) => props.theme.colors.backgroundLight};
      color: ${(props) => props.theme.textTheme.primaryColor};
    }
  }

  a {
    align-items: center;
    border-top-left-radius: ${(props) => props.theme.universalBorderRadius};
    border-bottom-left-radius: ${(props) => props.theme.universalBorderRadius};
    color: white;
    display: flex;
    height: 32px;
    margin-bottom: 7px;
    padding: 0px 20px;
    text-decoration: none;
    width: 100%;
  
    i {
      font-size: 24px;
      margin-right: 16px;
    }

    div {
      &.menu-content {
        margin-left: 16px;
        transition: opacity .3s cubic-bezier(0.4, 0, 0.2, 1);
        white-space: nowrap;
      }
    }
  }

  span {
    &.collapsed {
      opacity: 0;
    }

    -webkit-transition: opacity 0.7s ease-in-out;
    -moz-transition: opacity 0.7s ease-in-out;
    -o-transition: opacity 0.7s ease-in-out;
    transition: opacity 0.7s ease-in-out;
  }
`;

export const StyledAddLink = styled.span`  
  display: flex;
  align-items: center;

  a {
    align-items: center;
    border: none;
    border-radius: ${(props) => props.theme.universalBorderRadius};
    color: ${(props) => props.solid ? 'white' : props.theme.colors.primary};
    cursor: pointer;
    display: flex; 
    //font-family: 'Ubuntu';
    text-align: center;
    font-size: ${(props) => props.theme.textTheme.fontSize.normal};
    height: 40px;
    justify-content: center;
    padding: 8px 10px 8px 10px;
    text-decoration: none;
    
    -webkit-box-shadow: 0px 5px 8px 0px rgba(153,153,153,1);
    -moz-box-shadow: 0px 5px 8px 0px rgba(153,153,153,1);
    box-shadow: 0px 5px 8px 0px rgba(153,153,153,1);

    i {
      font-size: 24px;
      margin-left: 10px;
      color: ${(props) => props.theme.colors.primary};
    }

    ${(props) => props.popOverButton && css`
      color: ${(props) => props.theme.colors.secondary};
      justify-content: left;


      &:first-child {
        border-radius: 0;
        border-bottom: 1px ${(props) => props.theme.colors.secondary} solid;
      }
    `}

    ${(props) => props.noShadow && css`
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    `}

    ${(props) => props.noPadding && css`
      padding: 0;
    `}
  }
`;

export const AddButtonLink = (props) => {
  return <StyledAddLink {...props}>
    {props.children}
  </StyledAddLink>
}