import { Button, LoadingAnimation } from '@keoworld/gbl-ui-kit'
import { useContext, useState } from 'react'
import styled from 'styled-components'
import { ArrowButton } from '../../components/button'
import { AlertContext } from '../../containers/alert'
import ButtonGroup from '../../containers/button-group'
import Form from '../../containers/form'
import DropdownTitle from './dropdown-title'
import FormContainer from './form-container'

/**
 *
 * @param {Object[]} formSkema
 * @param {Object} form
 * @param {Funcion} setForm
 * @param {boolean} open - Form card open state
 * @param {Funcion} setOpen - Call to action on toggle button
 */
const GeneralInfo = ({
  formSkema,
  form,
  setForm,
  saveForm,
  open,
  setOpen,
  isLoadingSubmit,
  title = 'Customer Profile'
}) => {
  const [formErrors, setFormErrors] = useState({})
  const [isValidating, setIsValidating] = useState(false)
  const { setAlert } = useContext(AlertContext)

  const handleSubmit = async () => {
    setIsValidating(true)
    const hasErrors = Object.entries(formErrors).length === 0 ? false : true
    if (!hasErrors) {
      saveForm()
    } else {
      setAlert({
        title: 'Information',
        label:
          'Remember to save, verify and complete the entire form before proceeding.',
        type: 'info'
      })
    }
  }

  return (
    <GeneralInfoStyled>
      <DropdownTitle>
        <h3>{title}</h3>
        <ArrowButton onClick={() => setOpen()} up={!open} />
      </DropdownTitle>
      <FormContainer isVisible={open}>
        <Form
          skema={formSkema}
          controller={form}
          setController={setForm}
          formErrors={formErrors}
          setFormErrors={setFormErrors}
          isValidating={isValidating}
        />
        <ButtonGroup>
          <div />
          <Button
            solidAlt
            disabled={isLoadingSubmit}
            onClick={() => {
              handleSubmit()
            }}
          >
            {isLoadingSubmit ? (
              <LoadingAnimation className="loading" />
            ) : (
              'Save And Continue'
            )}
          </Button>
        </ButtonGroup>
      </FormContainer>
    </GeneralInfoStyled>
  )
}

const GeneralInfoStyled = styled.div`
  .loading span {
    background-color: white;
  }
`

export default GeneralInfo
