import { Button, LoadingAnimation } from '@keoworld/gbl-ui-kit'
import { useContext } from 'react'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { AddButton, ArrowButton } from '../../components/button'
import { AlertContext } from '../../containers/alert'
import ButtonGroup from '../../containers/button-group'
import Table from '../../containers/table'
import { formBuilder } from '../../utilities'
import { CUSTOMER_FORMS } from '../../utilities/constants/form_skemas'
import { CUSTOMER_HEADERS } from '../../utilities/constants/table-headers'
import {
  formatCreateOwner,
  formatInitialFormOwner
} from '../../utilities/functions/format-form'
import FormModal from '../customer-modal/form-modal'
import DropdownTitle from './dropdown-title'
import FormContainer from './form-container'

const OWNER_FORM_SKEMA = [
  ...CUSTOMER_FORMS.FORM_ADD_OWNER,
  ...CUSTOMER_FORMS.FORM_OWNER_LOCATION
]

const Shareholders = ({
  form,
  setForm,
  saveForm,
  open,
  setOpen,
  isLoadingSubmit
}) => {
  const initForm = formBuilder(OWNER_FORM_SKEMA)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [shareholders, setShareholders] = useState([])
  const [shareholdForm, setShareholdForm] = useState(initForm)
  const { setAlert } = useContext(AlertContext)

  useEffect(() => {
    form.sort((a, b) => a.id - b.id)
    setShareholders(form)
  }, [form])

  const onModalSubmit = async (id) => {
    let currRows = id
      ? shareholders.filter((e) => e.id !== id)
      : [...shareholders]
    let shareholdFormSend = {}
    Object.entries(shareholdForm).forEach(([key, value]) => {
      shareholdFormSend[key] = typeof value === 'string' ? value.trim() : value
    })
    const formatForm = await formatCreateOwner(shareholdFormSend)
    currRows.push(formatForm)
    currRows.sort((a, b) => {
      const first = a.id ?? Number.MAX_SAFE_INTEGER
      const second = b.id ?? Number.MAX_SAFE_INTEGER
      return first - second
    })
    setShareholders(currRows)
    setIsModalOpen(false)
    setShareholdForm(initForm)
    setForm(currRows)
    showAlert()
  }

  const editHandler = async (owner) => {
    const dataOwner = await formatInitialFormOwner(owner)
    setShareholdForm(dataOwner)
    setIsModalOpen(true)
  }

  const removeHandler = async (owner, index) => {
    const array = shareholders
    array.splice(index, 1)
    setForm(array)
    showAlert()
  }

  const showAlert = () => {
    setAlert({
      title: '¡Important!',
      label:
        'Remember that this change will not take effect until you click the "Save And Continue" button',
      type: 'info'
    })
  }

  return (
    <ShareholdersStyled>
      <DropdownTitle>
        <h3>Business Owners Profile</h3>
        <ArrowButton onClick={() => setOpen()} up={!open} />
      </DropdownTitle>
      <FormContainer isVisible={open}>
        <Table
          headers={CUSTOMER_HEADERS.OWNER_HEADERS}
          rows={shareholders}
          onEditClick={(owner) => editHandler(owner)}
          onRemoveClick={(owner, index) => removeHandler(owner, index)}
        />
        <AddButton
          onClick={() => {
            setShareholdForm(initForm)
            setIsModalOpen(true)
          }}
        >
          <b>Add Owners</b>
        </AddButton>
        <ButtonGroup>
          <div />
          <Button
            solidAlt
            disabled={isLoadingSubmit}
            onClick={() => {
              saveForm(true)
            }}
          >
            {isLoadingSubmit ? (
              <LoadingAnimation className="loading" />
            ) : (
              'Save And Continue'
            )}
          </Button>
        </ButtonGroup>
        <FormModal
          openModal={isModalOpen}
          setOpenModal={setIsModalOpen}
          formSkema={OWNER_FORM_SKEMA}
          form={shareholdForm}
          setForm={setShareholdForm}
          onSubmit={() =>
            shareholdForm.id ? onModalSubmit(shareholdForm.id) : onModalSubmit()
          }
        />
      </FormContainer>
    </ShareholdersStyled>
  )
}

const ShareholdersStyled = styled.div`
  .loading span {
    background-color: white;
  }
`

export default Shareholders
