import { Button } from '@keoworld/gbl-ui-kit'
import { useContext, useEffect, useState } from 'react'
import { default as LocalButton } from '../../components/button'
import ButtonGroup from '../../containers/button-group'
//import InputFile from '../../components/inputFile';
import FileForm from '../../containers/file-form'
import { getCustomerFiles, uploadFile } from '../../services/files'
//import Form from '../../containers/form';
import { formBuilder } from '../../utilities'
import { CUSTOMER_FORMS } from '../../utilities/constants/form_skemas'
import { FILE_STAGES, FILE_STATES } from '../../utilities/constants/models'
import { objectToFormData } from '../../utilities/functions/form-data'
import { CustomerContext } from '../../providers/customer-provider'
import { useCallback } from 'react'
import { AlertContext } from '../../containers/alert'
import ModalUpload from '../../containers/modal-documents-upload'

/**
 *
 * @param {Object} props
 * @param {Function} props.onReturn
 * @param {Function} props.onNext
 * @param {string} props.step - Step id
 * @param {Object} props.formState - Full Form Current State
 */
const Documents = ({ onReturn, onNext, step, formState }) => {
  const [form, setForm] = useState(
    formState ?? formBuilder(CUSTOMER_FORMS.FORM_DOCUMENTS)
  )
  const [isLoading, setIsLoading] = useState({
    loadData: false,
    submitData: false
  })
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const { customer } = useContext(CustomerContext)
  const { setAlert } = useContext(AlertContext)

  const fetchFiles = useCallback(async () => {
    const { data } = await getCustomerFiles(customer?.customerId)
    const fileForm = data.reduce((acc, itm) => {
      const fileTypeName = itm?.name?.split('.')[0]
      return { ...acc, [fileTypeName]: itm }
    }, {})
    setForm((prev) => ({ ...prev, ...fileForm }))
  }, [customer])

  const handleUploadFiles = async () => {
    setIsLoadingSubmit(true)
    setIsLoading((prev) => ({ ...prev, submitData: true }))
    try {
      const formFileList = Object.entries(form)
      await Promise.all(
        formFileList.map(async ([typeFileName, file]) => {
          if (file && !file?.id) {
            const fileName = `${typeFileName}.${file?.name?.split('.').pop()}`
            const fileData = {
              file,
              name: fileName /*file.name*/,
              extension: file.type,
              customerId: customer?.customerId,
              stage: FILE_STAGES.OnBoarding,
              type: file?.fileTypeId,
              status: FILE_STATES.DRAFT
            }
            const formData = objectToFormData(fileData)
            await uploadFile(formData)
            return setAlert({
              title: 'Information',
              label: 'Saved files.',
              type: 'info'
            })
          } else {
            return null
          }
        })
      )
    } catch (err) {
      setAlert({
        title: 'Error',
        label: 'An error occurred, try again later',
        type: 'error'
      })
    } finally {
      setIsLoadingSubmit(false)
    }
    setIsLoading((prev) => ({ ...prev, submitData: false }))
  }

  const handleContinue = async () => {
    await handleUploadFiles()
    onNext(form, step)
  }

  useEffect(() => {
    fetchFiles()
  }, [fetchFiles])

  useEffect(() => {
    const interval = setInterval(() => {
      setAlert(undefined)
    }, 8000)
    return () => clearInterval(interval)
  }, [setAlert])

  return (
    <div className='form-container'>
      <h3>List of Documents</h3>
      <h4>ASSESSMENT</h4>
      <FileForm
        skema={CUSTOMER_FORMS.FORM_DOCUMENTS}
        controller={form}
        setController={setForm}
      />
      <ButtonGroup>
        <LocalButton
          disabled={isLoading?.submitData}
          onClick={() => onReturn()}
        >
          Back
        </LocalButton>
        <LocalButton
          disabled={isLoading?.submitData}
          onClick={() => handleUploadFiles()}
        >
          Save
        </LocalButton>
        <div />
        <Button
          disabled={isLoading?.submitData}
          onClick={() => setShowModal(true)}
        >
          Continue
        </Button>
        {showModal && (
          <ModalUpload
            setOpenModalUpload={setShowModal}
            messages={{
              msg: 'Are you sure you want to continue? The information that was not saved will be lost.',
              msgButton: 'Confirm'
            }}
            handleSave={handleContinue}
            isLoadingSubmit={isLoadingSubmit}
          />
        )}
      </ButtonGroup>
    </div>
  )
}

export default Documents
