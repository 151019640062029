import KEO_ISOLOGO from './misc/keo-isologo.svg';
import AMEX_LOGO from './misc/amex-logo.svg';
import STEP_CURRENT from './shapes/step-current.svg';
import STEP_VISITED from './shapes/step-visited.svg';
import STEP_UNVISITED from './shapes/step-unvisited.svg';
import ARROW_TOP from './shapes/arrow-down.svg';
import ARROW_BOTTOM from './shapes/arrow-top.svg';
import ICON_CLOSE from './shapes/icon-close.png';
import ICON_TICK from './shapes/icon-tick.svg';


export const STEPS = {
  STEP_CURRENT,
  STEP_VISITED,
  STEP_UNVISITED
}

export const ARROWS = {
  ARROW_TOP,
  ARROW_BOTTOM
}

export const ICONS = {
  ICON_CLOSE,
  ICON_TICK
}

const IMAGES = {
  KEO_ISOLOGO,
  AMEX_LOGO,
  STEPS,
  ARROWS,
  ICONS
};


export default IMAGES;