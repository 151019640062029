import axios from 'axios'
import { ENDPOINTS } from './config'


/**
 * 
 * @param {number} id - Customer id 
 * @returns 
 */
export const getCustomerInfo = (id) =>
  axios.get(ENDPOINTS?.customer?.info, { params: { id } })


/**
 *
 * @param {object} form
 * @param {object} form.id - Customer id
 * @returns
 */
export const updateCustomer = (form) =>
  axios.patch(ENDPOINTS?.customer?.update, form)

/**
 * 
 * @param {number} customerId 
 * @returns 
 */
export const requestLoginLink = (customerId) =>
  axios.post(ENDPOINTS.user.sendLink, {customerId}, {baseURL: `${process.env.REACT_APP_TEMP_PUBLIC_HOST}/api`})
