import { useContext, useState } from 'react'
//import Button from '../../components/button';
import ButtonGroup from '../../containers/button-group'
import GeneralInfo from '../customer-forms/general-info'
//import LegalRepr from '../customer-forms/legal-repr';
import Shareholders from '../customer-forms/shareholders'
//import BankInfo from '../customer-forms/bank-info';
import { CUSTOMER_FORMS } from '../../utilities/constants/form_skemas'
import { formBuilder } from '../../utilities'
import { Button } from '@keoworld/gbl-ui-kit'
import { useEffect } from 'react'
import { getCustomerInfo, updateCustomer } from '../../services/customer'
import styled from 'styled-components'
import {
  formatInitialForm,
  updateForm,
  updateOwners
} from '../../utilities/functions/format-form'
import { CustomerContext } from '../../providers/customer-provider'
import { useCallback } from 'react'
import ModalUpload from '../../containers/modal-documents-upload'
import { AlertContext } from '../../containers/alert'

const formSections = [
  'infoForm',
  'shareholdForm',
  'financialForm',
  'loanRequestForm'
]

/**
 *
 * @param {Object} props
 * @param {Function} props.onNext
 * @param {string} props.step - Step id
 * @param {Object} props.formState - Full Form Current State
 */
const CustomerForms = ({ onNext, step, formState }) => {
  const [infoForm, setInfoForm] = useState(
    formState?.infoForm ?? formBuilder(CUSTOMER_FORMS.FORM_INFORMATION)
  )
  const [shareholdForm, setShareholdForm] = useState(
    formState?.shareholdForm ?? []
  )
  const [financialForm, setFinancialForm] = useState(
    formState?.financialForm ??
      formBuilder(CUSTOMER_FORMS.FORM_FINANCIAL_INFORMATION)
  )
  const [loanRequestForm, setLoanRequestForm] = useState(
    formState?.loanRequestForm ?? formBuilder(CUSTOMER_FORMS.FORM_LOAN_REQUEST)
  )
  const [openedForms, setOpenedForms] = useState({
    infoForm: true,
    shareholdForm: false,
    legalRepForm: false
  })
  const [arrayClients, setArrayClients] = useState()
  const [showModal, setShowModal] = useState(false)
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)
  const { customer } = useContext(CustomerContext)
  const { setAlert } = useContext(AlertContext)

  const getCustomer = useCallback(async () => {
    try {
      const customerData = await getCustomerInfo(customer.customerId)
      setArrayClients(customerData.data.clients)
      const { formInformation, formFinancialInformation, formLoanRequest } =
        await formatInitialForm(customerData.data)

      setInfoForm(formInformation)
      setShareholdForm(customerData.data.owners ?? [])
      setFinancialForm(formFinancialInformation)
      setLoanRequestForm(formLoanRequest)
    } catch (error) {
      console.error(error)
    }
  }, [customer])

  const handleSave = async (whatForm, form) => {
    setIsLoadingSubmit(true)
    Object.entries(form).forEach(([key, value]) => {
      if (typeof value === 'string') form[key] = value.trim()
    })
    let updateFormData = {}
    try {
      if (whatForm === 'shareholdForm') {
        updateFormData = await updateOwners(customer.customerId, form)
      } else if (whatForm === 'financialForm') {
        updateFormData = updateForm(whatForm, form, arrayClients)
      } else {
        updateFormData = updateForm(whatForm, form)
      }
      await updateCustomer(updateFormData)
      getCustomer()
      setAlert({
        title: 'Saved',
        label: 'Saving was performed correctly',
        type: 'info'
      })
      handleOpenForm(whatForm, true)
    } catch (error) {
      setAlert({
        title: 'Error',
        label: 'An error occurred, try again later',
        type: 'error'
      })
    } finally {
      setIsLoadingSubmit(false)
    }
  }

  const onSubmit = () => {
    setIsLoadingSubmit(true)

    const fullInfoForm = {
      infoForm,
      shareholdForm,
      financialForm,
      loanRequestForm
    }
    onNext(fullInfoForm, step)
    setIsLoadingSubmit(false)
  }

  const handleOpenForm = (section, next = false) => {
    const nextSection = formSections[formSections.indexOf(section) + 1]
    setOpenedForms((prevState) => ({
      ...prevState,
      [section]: next ? false : !prevState[section],
      [nextSection]: next ? true : prevState[nextSection]
    }))
  }

  useEffect(() => {
    getCustomer()
  }, [getCustomer])

  useEffect(() => {
    const interval = setInterval(() => {
      setAlert(undefined)
    }, 8000)
    return () => clearInterval(interval)
  }, [setAlert])

  return (
    <SectionContainer>
      <div className='form-container'>
        <GeneralInfo
          formSkema={CUSTOMER_FORMS.FORM_INFORMATION}
          form={infoForm}
          setForm={setInfoForm}
          open={openedForms.infoForm}
          setOpen={(opt) => handleOpenForm('infoForm', opt)}
          saveForm={() => handleSave('infoForm', infoForm)}
          title='Customer Profile'
          isLoadingSubmit={isLoadingSubmit}
        />
      </div>
      <div className='form-container'>
        <Shareholders
          form={shareholdForm}
          setForm={setShareholdForm}
          open={openedForms.shareholdForm}
          setOpen={(opt) => handleOpenForm('shareholdForm', opt)}
          saveForm={() => handleSave('shareholdForm', shareholdForm)}
          isLoadingSubmit={isLoadingSubmit}
        />
      </div>
      <div className='form-container'>
        <GeneralInfo
          formSkema={CUSTOMER_FORMS.FORM_FINANCIAL_INFORMATION}
          form={financialForm}
          setForm={setFinancialForm}
          open={openedForms.financialForm}
          setOpen={(opt) => handleOpenForm('financialForm', opt)}
          saveForm={() => handleSave('financialForm', financialForm)}
          title='Financial Information'
          isLoadingSubmit={isLoadingSubmit}
        />
      </div>
      <div className='form-container'>
        <GeneralInfo
          formSkema={CUSTOMER_FORMS.FORM_LOAN_REQUEST}
          form={loanRequestForm}
          setForm={setLoanRequestForm}
          open={openedForms.loanRequestForm}
          setOpen={(opt) => handleOpenForm('loanRequestForm', opt)}
          saveForm={() => handleSave('loanRequestForm', loanRequestForm)}
          title='Loan Request'
          isLoadingSubmit={isLoadingSubmit}
        />
      </div>
      <ButtonGroup>
        <div className='end-section'>
          <Button solid onClick={() => setShowModal(true)}>
            Continue
          </Button>
        </div>
      </ButtonGroup>
      {showModal && (
        <ModalUpload
          setOpenModalUpload={setShowModal}
          messages={{
            msg: 'Are you sure you want to continue? The information that was not saved will be lost.',
            msgButton: 'Confirm'
          }}
          handleSave={onSubmit}
          isLoadingSubmit={isLoadingSubmit}
        />
      )}
    </SectionContainer>
  )
}

export default CustomerForms

const SectionContainer = styled.div`
  .end-section {
    width: 100%;
    text-align: center;
    margin-top: 2rem;
  }
`
