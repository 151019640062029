import axios from 'axios'
import { ENDPOINTS } from './config'
import { WORKFLOW_PROJECT_ID } from '../utilities/constants'

/**
 *
 * @param {string} customerId
 * @returns
 */
export const getCustomerInstance = async (customerId) =>
  axios.get(ENDPOINTS.workflow.instances, {
    params: {
      projectId: WORKFLOW_PROJECT_ID,
      instanceLocation: 'customers',
      principalId: customerId
    }
  })
 
/**
 *
 * @param {object} params
 * @param {string} params.workflowId
 * @param {string} params.principalId
 * @param {string} params.event
 * @param {object} params.form
 * @returns
 */
export const instanceTransition = ({ workflowId, principalId, event, form }) => {
  const body = {
    workflowId,
    principalId, 
    event,
    context: form
  }
  return axios.post(ENDPOINTS.workflow.transition, body )
}
